.company_sidebar {
  width: 100%;
}

.singleItemSlider_container {
  position: relative;
}

.singleItemSlider_container h3 {
  font-size: 24px;
}

.company_sidebar button {
  background-color: #2f4161;
  border-radius: 3px;
  width: 100%;
  margin: 0;
  cursor: pointer;
  color: #fff;
  margin-top: 5px;
  padding: 6px 16px;
  line-height: 32px;
  font-size: 15px;
  border: 1px solid transparent;
}

.contact_wrapper {
  height: 400px;
  background-color: #fff;
  width: 100%;
  margin-top: 4rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 0 3rem;
}

.contact_wrapper_hidden {
  display: none;
}

.company_sidebar h3 {
  margin: 40px 0 20px 0px;
}

.itemLink_slider {
  position: relative;
  overflow: hidden;
}

.itemLink_container {
  display: flex;
}

.itemLink_container a {
  flex-shrink: 0;
  width: 100%;
}

.arrowBack,
.arrowForw {
  position: absolute;
  height: 25px;
  width: 25px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.arrowBack {
  left: 0;
}

.arrowForw {
  right: 0;
}

.contact_header img {
  width: 8rem;
  height: auto;
}

.contact_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contact_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact_form input,
.contact_form textarea {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 1.5rem;
  outline: none;
  border-color: #eee;
  text-decoration: none;
  color: grey;
  border-style: solid;
  resize: none;
}

.contact_form textarea {
  height: 12rem;
}

.contact_submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact_submit button {
  padding: 1rem 1.5rem;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  background-color: #2f4161;
}

@media screen and (min-width: 768px) {
  .contact_header {
    justify-content: space-between;
  }
  .contact_header img {
    width: 5rem;
    margin-right: 1rem;
    height: auto;
  }
  .company_sidebar {
    width: 30%;
    margin-left: 5%;
  }

  .singleItemSlider_container h3 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .singleItemSlider_container h3 {
    font-size: 24px;
  }
  .contact_header img {
    width: 7rem;
    height: auto;
  }
}
