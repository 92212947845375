.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2%;
  margin-bottom: 2rem;
}

.item {
  flex-basis: 49%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 2%;
}

.item_bckg {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 75%;
}

.item_paragraph {
  position: absolute;
  opacity: 0;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  transition: 0.3s ease-in all;
}

.item_bckg:hover .item_paragraph {
  opacity: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-out all;
  background-color: #2f4161;
  border-radius: 3px;
}

.item_bckg:hover .gradient {
  opacity: 0.8;
}

.item_paragraph p {
  padding: 10px 12px;
  color: #fff;
  font-size: 12px;
}

.item h3 {
  text-align: center;
  padding: 2rem;
  font-weight: 500;
  font-size: 14px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.header h3 {
  cursor: pointer;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
}

.breadcrumbs h4 {
  cursor: pointer;
}

.gallery_360image {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px) {
  .breadcrumbs h4 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1024px) {
  .item {
    flex-basis: 31%;
    margin-bottom: 2.5%;
  }

  .item h3 {
    text-align: left;
  }

  .breadcrumbs p {
    margin: 0 0.5rem;
  }
}
