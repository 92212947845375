.container {
  width: 80%;
  margin: auto;
  padding: 40px 0;
}

.title {
  padding: 10px 0 34px;
}

.find {
  margin-top: 60px;
}

.input {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .container {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 40%;
  }
}
