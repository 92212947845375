.Navigation_upper_container {
  width: 100%;
  background-color: #2f4161;
}

.Navigation_upper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.Navigation_upper__left,
.Navigation_upper__right {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.Navigation_upper__left {
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;
  align-items: flex-start;
}

.Navigation_upper__right {
  justify-content: flex-end;
}

.Navigation_upper__left a {
  padding: 4px 0;
}

.Navigation_upper__right p {
  padding: 5px 10px 10px 0;
}

.TopBar_social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: visible;
}

.TopBar_social img {
  padding: 1.5rem 1rem 1.5rem 0;
  height: 100%;
}

.TopBar_info {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.TopBar_info_icon {
  size: 'lg';
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 13px;
}

.TopBar_info a {
  color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.TopBar_info a:hover {
  color: #d4d4d4;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media screen and (min-width: 768px) {
  .Navigation_upper__left p {
    padding-right: 20px;
  }

  .TopBar_info {
    margin-right: 20px;
  }
  .Navigation_upper__left {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .Navigation_upper {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    height: 4.5rem;
  }

  .Navigation_upper__left,
  .Navigation_upper__right {
    width: auto;
  }

  .TopBar_social {
    transform: translateY(0%);
    background-color: transparent;
    transition: 0.2s ease-in all;
  }

  .TopBar_social img {
    padding: 1.5rem 1.5rem;
  }

  .TopBar_social:hover {
    background-color: #fff;
    transition: 0.2s ease-out all;
    transform: translateY(-99%);
  }

  .TopBar_social:hover img {
    background-color: #fff;
  }
}
