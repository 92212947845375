.filter-slider__details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.filter-slider__details-center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.rc-slider-handle {
  border: 2px solid #2f4161 !important;
  height: 18px !important;
  width: 18px !important;
  top: 15% !important;
}

.rc-slider-rail {
  background-color: #e8e8e8 !important;
}

.rc-slider-track {
  background-color: #2f4161 !important;
}

.rc-slider-rail,
.rc-slider-track {
  height: 3px !important;
}

.rc-slider {
  width: 100% !important;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .rc-slider {
    width: 100% !important;
  }
}

@media screen and (min-width: 1600px) {
  .rc-slider {
    width: 100% !important;
  }
}
