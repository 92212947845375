.ServicePackages {
  display: flex;
  flex-direction: column;
}

.Boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Sellers,
.Manufacturers {
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 20% 0 20%;
}

.Sellers .Info h2,
.Manufacturers .Info h2 {
  font-size: 30px;
}

.Sellers .Info {
  color: #d4d4d4;
}

.Sellers {
  background-color: #2f4161;
}

.Manufacturers {
  background-color: #eee;
}

.ServicePackages .Info {
  width: 100%;
  margin: 0 auto;
}

.Info h2 {
  margin-bottom: 20px;
}

.BoxOne,
.BoxTwo,
.BoxThree {
  flex-basis: 100%;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 6px 6px 2px 0px rgba(0, 0, 0, 0.25);
}

.BoxOne:hover,
.BoxTwo:hover,
.BoxThree:hover {
  transform: scale(1.05);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.Boxes p {
  color: #808080;
}

.BoxTitle,
.BoxDesc {
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 10px;
}

.BoxTitle {
  padding-bottom: 10px;
}

.BoxTitle {
  margin-bottom: 20px !important;
  color: #808080;
}

.BoxDesc {
  padding-bottom: 10px;
}

.BoxDesc p {
  margin-bottom: 5px;
}

.BoxPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BoxPrice h5,
.BoxPrice p {
  font-size: 10px;
}

.BoxPrice h5,
.BoxPrice p {
  margin-bottom: 0;
}

.BoxPrice div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 1024px) {
  .ServicePackages {
    display: flex;
    flex-direction: column;
  }

  .Sellers,
  .Manufacturers {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 20% 0 20%;
  }

  .Sellers .Info h2,
  .Manufacturers .Info h2 {
    font-size: 30px;
  }

  .Sellers .Info {
    color: #d4d4d4;
  }

  .Sellers {
    background-color: #2f4161;
  }

  .Manufacturers {
    background-color: #eee;
  }

  .ServicePackages .Info {
    width: 60%;
    margin: 0 auto;
  }

  .Info h2 {
    margin-bottom: 20px;
  }

  .Boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .BoxOne,
  .BoxTwo,
  .BoxThree {
    flex-basis: 32%;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 6px 6px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .Boxes p {
    color: #808080;
  }

  .BoxTitle,
  .BoxDesc {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 10px;
  }

  .BoxTitle {
    padding-bottom: 10px;
  }

  .BoxTitle {
    margin-bottom: 20px !important;
    color: #808080;
  }

  .BoxDesc {
    padding-bottom: 10px;
  }

  .BoxDesc p {
    margin-bottom: 5px;
  }

  .BoxPrice {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .BoxPrice h5,
  .BoxPrice p {
    font-size: 10px;
  }

  .BoxPrice h5,
  .BoxPrice p {
    margin-bottom: 0;
  }

  .BoxPrice div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
