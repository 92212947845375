@keyframes loader-right-spin {
  0% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(-130deg);
  }
}

@keyframes loader-left-spin {
  0% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(130deg);
  }
}

@keyframes loader-easespin {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}

@keyframes loader-linspin {
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  /* background-color: #2f4161; TODO check this*/
  position: relative;
  overflow: hidden;
  z-index: 100000;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  width: 64px;
  z-index: 100000;
  background-color: #fff;
  pointer-events: none;
}

.loader--big {
  width: 160px;
  margin-left: -80px;
  display: flex;
  align-items: center;
  margin-bottom: 13rem;
  top: calc(50% - 13rem);
  justify-content: center;
}

.loader-image {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.loader-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  animation: loader-linspin 1568.23529647ms linear infinite;
}

.loader-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: loader-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 49%;
  overflow: hidden;
}

.loader-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 49%;
  overflow: hidden;
}

.loader-left .loader-circle {
  left: 0;
  right: -100%;
  border-right-color: transparent;
  animation: loader-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader-right .loader-circle {
  left: -100%;
  right: 0;
  border-left-color: transparent;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.loader-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  border-color: #ddd #ddd transparent;
  border-radius: 50%;
  border-width: 6px;
}

.loader--big .loader-circle {
  border-color: #000 #000 #000 transparent;
  border-width: 3px;
}

.loader--big .loader-right {
  left: 50%;
}

.loader--big .loader-left {
  right: 50%;
}

.loader--big .loader-left .loader-circle {
  animation: none;
}
