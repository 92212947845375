.companyHeader {
  background: linear-gradient(to bottom, #f7f7f7 0%, rgba(255, 255, 255, 0.5));
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 4.5rem;
  justify-content: space-between;
}

.companyHeader__left {
  flex-direction: column;
  display: flex;
}

.basicInfo {
  flex-direction: column;
}

.basicInfo__upper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.basicInfo__upper h2 {
  font-size: 28px;
  line-height: 36px;
  margin-right: 10px;
}
.fakeButton {
  align-self: center;
  background-color: #2f4161;
  padding: 4px 11px;
  color: #fff;
  border-radius: 3px;
  line-height: 24px;
}

.fakeButton p {
  font-size: 1.3rem;
}

.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.location p {
  color: #808080;
  font-size: 17px;
  color: #888;
}

.companyLogo {
  max-width: 12rem;
  height: auto;
}

.companyHeader__right {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

@media screen and (min-width: 540px) and (orientation: portrait) {
  .companyHeader {
    flex-direction: row;
    align-items: flex-start;
  }

  .companyHeader__right {
    margin-top: 0;
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (min-width: 540px) {
  .basicInfo__upper {
    flex-direction: column;
  }

  .fakeButton p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media screen and (min-width: 768px) {
  .companyHeader {
    flex-direction: row;
    align-items: center;
    padding-top: 5.5rem;
  }

  .companyHeader__right {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  }

  .companyHeader__left {
    flex-direction: row;
    /* align-items: center; */
  }

  .basicInfo {
    margin-left: 20px;
    width: 100%;
  }

  .basicInfo__upper {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .fakeButton {
    margin-left: 1rem;
  }

  .basicInfo__upper h2 {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1280px) {
  .companyHeader {
    padding: 40px 90px;
  }
}

@media screen and (min-width: 1440px) {
  .companyHeader {
    padding: 40px 140px;
  }
}
