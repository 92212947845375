.TermsOfUse {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.TermsOfUse h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.TermsOfUse h6 {
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.TermsOfUse p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #888888;
}

.TermsOfUse span {
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

.Italic {
  font-style: italic;
  color: #000 !important;
  margin-bottom: 40px !important;
}