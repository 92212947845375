.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
}

.notFoundText {
  font-size: 30px;
  color: #2f4161;
}
