.cluster_marker {
  color: #fff;
  background: #2f4161;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 16px;
  align-items: center;
  transform: scale(1) translate(-50%, -100%);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }

  50% {
    /* transform: scale(1) translate(-50%, -100%); */
    box-shadow: 0 0 0 10px rgba(17, 43, 58, 0.3);
  }

  100% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }
}

.selectedCompanyWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 25vmin;
  height: auto;
  left: 0;
  transform: translate(-50%, -100%);
  top: 0;
  cursor: default;
  justify-content: center;
  align-content: center;
  z-index: 1;
}

.selectedCompanyContainer {
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.closeSelectedCompany {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  color: #000;
  z-index: 10;
}

.pinTriangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #fff;
  align-self: center;
}
