.Select {
  width: 100%;
  background-color: #fcfcfc;
  color: #888;
  margin-bottom: 14px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  position: relative;
}

.Select_optionsVisible {
  border-radius: 0;
}

.Select_title {
  width: 100%;
  border: 0;
  padding-left: 1.5rem;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  display: flex;
  outline: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5rem;
  color: #888888;
}

.Select_title img {
  height: 15px;
}

.Select_options {
  display: none;
}

.Select_options_visible {
  padding: 0 8px;
  display: block;
  max-height: 14vmax;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 2;
  padding-bottom: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Select_options_visible::-webkit-scrollbar {
  width: 6px;
}

.Select_options_visible::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.Select_options_visible::-webkit-scrollbar-thumb {
  background: #2f4161;
  border-radius: 10px;
}

.Select_cancelOption {
  display: block;
  border: 0;
  background-color: transparent;
  padding: 10px 10px;
  line-height: 15px;
  word-wrap: break-word;
  text-align: left;
  width: 100%;
  transition: 200ms ease all;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  color: rgb(204, 204, 204);
}

.Select_singleOption {
  display: block;
  border: 0;
  background-color: transparent;
  padding: 10px 10px;
  line-height: 15px;
  word-wrap: break-word;
  text-align: left;
  width: 100%;
  transition: 200ms ease all;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}

.Select_singleOption:hover,
.Select_singleOption_selected {
  background-color: #2f4161;
  color: #fff;
  border-radius: 4px;
}

.Select_cancelOption:hover,
.Select_cancelOption_selected {
  background-color: #2f4161;
  color: #fff;
  border-radius: 4px;
}

@media (min-width: 1024px) {
  .Select {
    width: 23%;
    margin-bottom: 0;
    margin-right: 20px;
  }
}
