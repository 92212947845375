.companiesScreen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.mapContainer {
  position: relative;
  top: 0;
  left: 0;
  z-index: 20;
  height: 50vh;
  width: 100%;
  margin-bottom: 0px;
  background-color: #fff;
}

.companiesListContainer {
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0;
}

.companiesList {
  padding: 0 40px;
}

@media screen and (min-width: 768px) {
  .companiesList {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1024px) {
  .companiesScreen {
    height: calc(100vh - 255px);
    flex-direction: row;
  }

  .mapContainer {
    height: 100%;
    width: 45%;
  }

  .companiesListContainer {
    width: 55%;
  }
}

@media screen and (min-width: 1280px) {
  .companiesScreen {
    height: calc(100vh - 145px);
  }

  .companiesList {
    padding: 0 40px;
  }
}
