.rowItem {
  flex-basis: 100%;
  margin-bottom: 4%;
}
.twoRowItem {
  flex-basis: 100%;
  margin-bottom: 4%;
}
.threeRowItem {
  flex-basis: 100%;
  margin-bottom: 7%;
}

.list_buttons {
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.list_button {
  width: 36px;
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.list_button:hover {
  background-color: #2f4161;
  transition: all 0.4s;
}
.list_button svg {
  color: #a0a0a0;
}
.list_button:hover svg {
  color: #f0f0f0;
}

.list_button:nth-child(3) {
  display: none;
}

@media screen and (min-width: 768px) {
  .twoRowItem {
    flex-basis: 48.75% !important;
  }

  .threeRowItem {
    flex-basis: 31.66%;
    margin-bottom: 4%;
  }

  .rowItem {
    flex-basis: 100%;
    width: 100%;
  }

  .list_buttons {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .list_button:nth-child(3) {
    display: block;
  }

  .rowItem {
    flex-basis: 100%;
  }
}
