.Facebook,
.Pinterest,
.Instagram,
.Twitter,
.Linkedin {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: visible;
}

.Facebook img,
.Pinterest img,
.Instagram img,
.Twitter img,
.Linkedin img {
  padding: 1.5rem 1rem 1.5rem 0;
}

.Footer {
  width: 100%;
  background-color: #fcfcfc;
  box-shadow: inset 0 20px 50px -20px rgba(0, 0, 0, 0.06);
  bottom: 0;
  z-index: 1;
}
.Footer h4 {
  color: #333;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.Footer_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 70px 0;
}

.Footer_info {
  display: flex;
  flex-direction: column;
  color: #808080;
}
.Footer_info img {
  height: 4rem;
  margin-bottom: 1.7rem;
}
.Footer_info p {
  margin: 0 0 17px;
  line-height: 24px;
  font-size: 15px;
}
.Footer_links {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  color: #808080;
  justify-content: flex-start;
}

.Footer_links_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Footer_links h4 {
  color: #333;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.Footer_link_nav {
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 15px;
  line-height: 27px;
}

.Footer_links_right {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
}

.Footer_links_left {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
}

.Footer_links a:hover {
  color: #808080;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_contact {
  width: 100%;
}

.Footer_contact__info {
  display: flex;
  flex-direction: column;
  color: #808080;
  margin-bottom: 10px;
}

.Footer_contact__info a {
  color: #707070;
  font-size: 15px;
  line-height: 27px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_contact__info a:hover {
  color: #808080;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_contact__social_links {
  display: flex;
  width: 50%;
  color: #808080;
  height: 60px;
  overflow: hidden;
  align-items: center;
}

.Footer_copyright {
  display: flex;
  color: #707070;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding-top: 40px;
  font-size: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .Footer {
    position: fixed;
  }

  .Footer_container {
    padding-top: 70px;
  }

  .Footer_main_container {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
  }
  .Footer_info {
    flex-basis: 50%;
    padding-right: 15px;
  }
  .Footer_links {
    flex-basis: 50%;
    padding-left: 15px;
  }

  .Footer_links_container {
    align-items: flex-start;
    width: 100%;
  }

  .Facebook,
  .Instagram,
  .Twitter,
  .Linkedin {
    transform: translateY(0%);
    background-color: transparent;
    transition: 0.2s ease-in all;
  }

  .Facebook:hover,
  .Instagram:hover,
  .Twitter:hover,
  .Linkedin:hover {
    transition: 0.2s ease-out all;
    transform: translateY(-100%);
  }
}

@media screen and (min-width: 1024px) {
  .Footer_main_container {
    display: flex;
    flex-wrap: wrap;
  }

  .Footer_info {
    flex-basis: 42%;
  }
  .Footer_links {
    flex-basis: 33%;
  }
  .Footer_contact {
    flex-basis: 25%;
  }
}
