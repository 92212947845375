.controls {
  height: 51px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}
.moreOptions {
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin: 5px 0 0 0;
  margin-top: 35px;
  background-color: transparent;
}
.moreOptions span {
  font-size: 15px;
}

.OptionsContainer {
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}

.Option {
  font-size: 15px;
  margin-top: 9px;
  margin-right: 20px;
  color: #707070;
  display: flex;
  align-items: center;
}
.Option input {
  height: 19px;
}
.Option span {
  font-size: 15px;
  padding-left: 10px;
}

@media screen and (min-width: 768px) {
  .OptionsContainer {
    flex-direction: row;
  }
}
