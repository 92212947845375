.HowCanWeHelp_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0;
}

.SecondSection {
  width: 100%;
}
