.container {
  padding: 32px 40px 47px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container form {
  width: 100%;
}
.title {
  margin: 10px 0 34px 0;
  font-size: 24px;
  line-height: 1.1;
  color: #333;
  font-weight: 500;
}

.input,
.Select_title,
.findButton,
.controls {
  height: 51px;
}

.sliderContainer {
  margin: 2rem auto 2rem auto;
  width: 100%;
}

.formWithSlider {
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.findButton {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0 40px;
  border: 0;
  outline: none;
  border-radius: 4px;
  height: 51px;
  line-height: 51px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.input {
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

@media (min-width: 1024px) {
  .sliderContainer {
    padding: 0 0 0 2.5rem;
    width: 49%;
  }
  .formWithSlider {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .space_between {
    display: flex;
    justify-content: space-between;
  }

  .input {
    width: 100%;
  }

  .Select {
    margin: 10px 0 10px;
    width: 49%;
  }

  .buttons {
    margin-top: 10px;
  }
}
