.App {
  display: flex;
  flex-direction: column;
  color: #000;
  z-index: 3;
  position: relative;
}

.Content {
  z-index: 3;
  transform: none;
  transition: 300ms ease transform;
  position: relative;
  background: linear-gradient(to bottom, #f7f7f7 0%, rgba(255, 255, 255, 0.5));
  background-color: #fff;
  min-height: 100vh;
}

.Content--translated {
  transform: translateX(80%);
}

.BackToTop {
  position: fixed;
  width: 5rem;
  height: 5rem;
  background-color: #2f4161;
  bottom: 4%;
  right: 2.5%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s ease-in opacity;
}

.BackToTop--visible {
  opacity: 1;
  transition: 0.2s ease-out opacity;
}

.Footer-margin {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .Footer-margin {
    margin-bottom: 639px;
  }
}

@media screen and (min-width: 1024px) {
  .Footer-margin {
    margin-bottom: 441px;
  }
}
