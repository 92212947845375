.container {
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
}

.first {
  padding: 6px;
  background-color: #2f4161;
}

.shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.noShadow {
  box-shadow: none;
}

.top {
  position: relative;
  padding-bottom: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.bottom {
  padding: 15px 25px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 500ms ease opacity;
  background: linear-gradient(to bottom, transparent 60%, #2f4161);
}

@media (hover: hover) {
  .container:hover .gradient {
    opacity: 1;
  }
}

.top_highlighted {
  transform: rotate(-45deg);
  display: flex;
  position: absolute;
  top: 17px;
  background-color: #2f4161;
  width: 200px;
  left: -67px;
  font-size: 12px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.top_premium {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  display: flex;
  padding: 1px 10px;
  font-size: 12px;
  float: right;
  background-color: rgba(74, 74, 76, 0.7);
  height: 30px;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.description_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.description {
  color: #909090;
  line-height: 24px;
  font-size: 14px;
  margin-right: 0.5rem;
}

@media screen and (min-width: 768px) {
  .altShadow {
    display: flex;
  }
  .altTop {
    padding-bottom: 29%;
    width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .bottom {
    padding: 8%;
  }
}
