.tabs {
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  overflow-x: hidden;
}

.container {
  display: flex;
  margin-bottom: 0px;
  align-items: center;
  overflow-x: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.tab {
  display: inline-block;
  flex-shrink: 0;
  padding: 16px 5px 8px 5px;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  color: #333333;
  font-weight: 500;
  cursor: pointer;
  margin-right: 4px;
}

.tab p {
  font-size: 1.4rem;
}

.tab:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2f4161;
  height: 2px;
  transform: translateY(4px);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.tab_active {
  color: #2f4161;
}

.tab_active:before,
.tab:hover,
.tab:hover:before,
.tab:focus:before,
.tab:active:before {
  transform: translateY(0);
  color: #2f4161;
}

@media screen and (min-width: 768px) {
  .tab p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 70%;
  }

  .tab {
    font-size: 16px;
  }
}
