.FindSolution {
  padding: 100px 0;
  background-image: url('../../../assets/images/findsolution.jpg');
  background-size: cover;
  background-attachment: fixed;
  transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
  color: #fff;
  overflow: hidden;
  position: relative;
  margin-top: -10px;
}

.FindSoluton_title {
  margin: 20px 0 30px;
  font-size: 40px;
}
.FindSolution_Select {
  font-size: 17px;
}

.FindSolution_advanced {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}

.FindSolution_advaned_title {
  font-size: 15px;
}

.FindSolution_advanced_button {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 7px 12px;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
}

.FindSolution_advanced_button:hover {
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.25);
}

.FindSolution_solutions {
  margin-top: 40px;
  font-size: 24px;
  font-weight: normal;
}

.FindSolution_form_input {
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 4px;
  border-color: transparent;
}

.FindSolution_form_button {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0;
  border: 0;
  outline: none;
  height: 57px;
  line-height: 57px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: opacity 0.2s ease-in;
  opacity: 0.85;
}

.FindSolution_form_button:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.FindSolution_form_button img {
  height: 18px;
}

@media (min-width: 768px) {
  .FindSolution {
    padding: 100px 0;
  }

  .FindSolution_form_buttonWrapper {
    display: flex;
    justify-content: space-between;
  }

  .FindSolution_advanced {
    flex-direction: row;
    align-items: center;
  }

  .FindSolution_advanced_button {
    margin-left: 7px;
  }

  .FindSolution_form_button {
    width: 130px;
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  .FindSolution {
    padding: 125px 0;
  }

  .FindSolution_form {
    display: flex;
    align-items: center;
  }

  .FindSolution_form_input {
    margin-bottom: 0;
  }

  .FindSolution_form_buttonWrapper {
    flex: 1;
  }
}

@media screen and (min-width: 1280px) {
  .FindSolution {
    padding: 170px 0;
  }
}
