.input {
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}
