.backArrow {
  display: flex;
  height: 60px;
  width: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eee;
  margin: 0 25px 0 0;
  transition: 0.2s background-color ease;
}

@media screen and (min-width: 768px) {
  .backArrow {
    margin-bottom: 0;
  }

  .backArrow:hover {
    background-color: #2f4161;
    transition: 0.2s background-color ease;
  }
}
