@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  font-family: 'Varela Round', 'HelveticaNeue', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #2f4161;
}

p,
a,
li,
span,
button {
  font-size: 1.3rem;
}

button {
  border: none;
  outline: none;
}

h3 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2rem;
}

.noScrollable {
  overflow: hidden;
}

#root {
  height: 100%;
}

.global-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.gradient {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5)
  );
  background-color: #f7f7f7;
}

@media (min-width: 768px) {
  .global-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .global-container {
    width: 970px;
  }

  button {
    font-size: 1.5rem;
  }
}

@media (min-width: 1240px) {
  .global-container {
    width: 1090px;
  }
}

@media (min-width: 1367px) {
  .global-container {
    width: 1210px;
  }
}
