.singleProductScreen {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  margin-top: 2rem;
}

.product_header {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-items: flex-start;
  max-width: 100%;
  margin-bottom: 2rem;
}

.product_header__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

.product_price,
.product_price__discount {
  font-size: 1.6rem !important;
  color: #2f4161 !important;
  margin-top: 2rem;
}

.product_price_disabled {
  text-decoration: line-through;
  color: #d4d4d4 !important;
}

.product_price__discount {
  margin-top: 0.5rem;
}

.product_header_image {
  width: 100%;
}

.product_header_content {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product_title {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.product_title div {
  display: flex;
  flex-direction: column;
}

.product_filters {
  width: 20%;
}

.product_filters img {
  height: 10vmin;
}

.product_title p {
  font-size: 18px;
  color: #808080;
  margin: 0.3rem 0;
}

.product_title a {
  margin-bottom: 1rem;
}

.product_decription {
  margin: 0 0 1rem;
  font-size: 14px;
  color: #888888;
  line-height: 22px;
}

.product_info {
  background: #eee;
  color: #909090;
  padding: 1rem 1.6rem 1rem 1.5rem;
  margin: 1rem 0;
  height: 10vmax;
  overflow-y: hidden;
}

.product_info div {
  overflow-y: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
}

.product_info div::-webkit-scrollbar {
  width: 10px;
}

.product_info div:hover {
  overflow-y: scroll;
}

.product_info div::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.product_info div::-webkit-scrollbar-thumb {
  background: #2f4161;
  border-radius: 10px;
}

.product_info span {
  font-size: 14px;
  font-weight: 700;
}

.product_info p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.product_info img {
  margin-right: 0.8rem;
  width: 30px;
}

@media screen and (min-width: 640px) {
  .product_filters img {
    height: 8vmin;
  }
}

@media screen and (min-width: 768px) {
  .product_header_image {
    width: 100%;
    height: 100%;
    max-height: 20vmax;
    object-fit: cover;
    padding: 1rem;
    padding-right: 0;
  }

  .product_header_content {
    width: 60%;
  }

  .product_header {
    display: flex;
    flex-direction: row;
  }

  .product_filters img {
    height: 6vmin;
  }

  .product_header__left {
    width: 40%;
    align-items: flex-end;
  }

  .product_title {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .product_filters img {
    height: 4vmax;
  }

  .product_header {
    margin-top: 0rem;
  }

  .product_info {
    padding: 3rem 1rem 3rem 3rem;
  }
}

@media screen and (min-width: 1280px) {
  .product_header {
    margin-top: 2%;
  }

  .product_filters img {
    height: 3vmax;
  }
}
