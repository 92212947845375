.list {
  display: flex;
  padding-top: 20px;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0 25px 0;
}

.items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5%;
}
.list_info {
  color: #888;
  font-size: 15px;
}

.header_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 1rem;
  min-height: 40px;
}

.header_left span {
  width: 30%;
}

.select {
  border: none;
  background-color: #fff;
  width: 70%;
  margin-left: 1rem;
}

@media screen and (min-width: 768px) {
  .list_buttons {
    display: flex;
  }

  .header_left {
    justify-content: flex-start;
  }

  .header_left span {
    width: unset;
  }

  .select {
    width: 35%;
  }
}

@media screen and (min-width: 1024px) {
  .list_button:nth-child(3) {
    display: block;
  }

  .select {
    width: 25%;
  }
}

@media screen and (min-width: 1280px) {
  .select {
    width: 22%;
  }
}

@media screen and (min-width: 1440px) {
  .select {
    width: 20%;
  }
}
