.companyBanner {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.active_image {
  border: 3px solid #2f4161;
  border-radius: 3px;
}
.companyBanner__items {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 2%;
  overflow-x: scroll;
  padding-bottom: 1.5rem;
}

.companyBanner__items::-webkit-scrollbar {
  width: 6px;
}

.companyBanner__items::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

.companyBanner__items::-webkit-scrollbar-thumb {
  display: none;
  background: #2f4161;
  border-radius: 1rem;
}

.image {
  width: 32%;
  object-fit: cover;
  height: 83px;
  cursor: pointer;
  border-radius: 3px;
}

.Gallery_About {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.94);
  z-index: 10;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.Gallery_About_Visible {
  display: flex;
  pointer-events: all;
}

.companyBanner__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  cursor: pointer;
  height: 20rem;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) {
  .companyBanner__items {
    gap: 1.33%;
  }
  .companyBanner__img {
    height: 40rem;
  }
  .image {
    height: 11.4rem;
    width: 24%;
  }
}

@media screen and (min-width: 1024px) {
  .companyBanner__items {
    gap: 1%;
  }
  .companyBanner__img {
    height: 50rem;
  }
  .image {
    height: 10rem;
    width: 15.83%;
  }
}

@media screen and (min-width: 1280px) {
  .companyBanner__img {
    height: 60rem;
  }
  .image {
    height: 11.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .image {
    height: 12.5rem;
  }
}
