.Navigation {
  display: flex;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  top: -5rem;
  justify-content: center;
  background-color: #fff;
  transition: 0.3s all ease-out;
  transform: translateY(0);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.Navigation_transformed {
  transition: 0.3s all ease-in;
}

.button:hover,
.Main_button:hover {
  background-color: #2f4161;
  color: #fff;
  transition: 0.25s all ease-out;
}
.Main_button {
  color: #000;
  transition: 0.25s all ease-out;
  border-radius: 5px;
  outline: none;
}

.Navigation_lower_container {
  width: 100%;
}

.Navigation_lower_container {
  background-color: #fff;
}

.Navigation p {
  color: #fff;
}

.Navigation_lower {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.Navigation_lower__upper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 93px;
  border-bottom: 1px solid #ccc;
}

.Navigation_lower__lower {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
}

.Navigation_lower__lower p {
  color: #000;
}

.Navigation_large,
.Navigation_large_desktop {
  display: none;
}

.Menu_mobile {
  width: 50px;
  background-color: #eee;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.Navigation_lower {
  width: 100%;
}

.button {
  padding: 6px 16px;
  line-height: 32px;
  font-size: 15px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.main {
  color: #2f4161;
  border: 1px solid #2f4161;
}

.Navigation_large a,
.Navigation_large_desktop a {
  margin: 0 5px;
}

.Navigation_large a:nth-child(1),
.Navigation_large_desktop a:nth-child(1) {
  margin: 0 5px;
}

.Logo {
  height: 50px;
  width: 30%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 25%;
}

.BurgerSpan {
  height: 3px;
  width: 25px;
  display: block;
  background-color: #000;
  transition: all 0.25s ease-in-out;
  position: absolute;
  border-radius: 10px;
}

.BurgerSpan_top {
  top: 40%;
}

.BurgerSpan_center {
  top: 48%;
}

.BurgerSpan_bottom {
  top: 56%;
}

@media screen and (min-width: 768px) {
  .Navigation_transformed {
    transform: translateY(-100%);
    transition: 0.3s all ease-in;
  }
  .Navigation {
    top: -4.5rem;
  }
  .Navigation_lower {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .Menu_mobile {
    display: none;
  }
  .Navigation {
    position: sticky;
  }
  .Navigation_lower {
    justify-content: space-between;
    position: relative;
  }

  .Navigation_lower__lower {
    position: absolute;
    right: 0;
    height: 100%;
    width: 230px;
    margin-right: 15px;
  }

  .Logo {
    position: inherit;
    margin: 0;
    width: auto;
  }

  .Navigation_large {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding: 30px 15px;
  }
}

@media screen and (min-width: 1280px) {
  .Navigation_large {
    display: none;
  }

  .Navigation_large_desktop {
    display: flex;
    margin-left: 40px;
  }

  .Navigation_lower__upper {
    border-bottom: none;
  }

  .Navigation_lower__lower {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .Navigation_lower__lower {
    padding: 20px 0;
  }
}
