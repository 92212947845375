.container {
  padding: 0;
}

.title {
  text-align: center;
  font-size: 28px;
  line-height: 58px;
  font-weight: normal;
}

.item_container {
  height: 350px;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: #2f4161;
  border-radius: 3px;
  margin-bottom: 25px;
}

.item_container img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
}

.item_container:hover img {
  transform: scale(1.5);
  transition: 0.5s ease;
  opacity: 0.3;
}

.subtitle {
  color: #888;
  font-size: 18px;
  text-align: center;
  display: block;
}

.content {
  margin-top: 40px;
}

.item_title {
  font-size: 28px;
  line-height: 36px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.item_highlighted {
  transform: rotate(-45deg);
  display: flex;
  position: absolute;
  top: 17px;
  background-color: #2f4161;
  width: 200px;
  left: -67px;
  font-size: 12px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

@media (min-width: 768px) {
  .container {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item_container {
    margin-bottom: 0;
  }
  .item_container:nth-child(1) {
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .item_container:nth-child(2) {
    margin-left: 1%;
    margin-bottom: 1%;
  }

  .item_container:nth-child(3) {
    margin-right: 1%;
    margin-top: 1%;
  }
  .item_container:nth-child(4) {
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 25px;
  }

  .item_container:nth-child(1),
  .item_container:nth-child(4) {
    width: 33%;
  }

  .item_container:nth-child(2),
  .item_container:nth-child(3) {
    width: 65%;
  }
}
