.container {
  width: 100%;
  padding: 32px 0px 47px 0px;
}

.title {
  margin: 10px 0 34px 0;
  font-size: 24px;
  line-height: 1.1;
  color: #333;
  font-weight: 500;
}

.upper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.99%;
}

.input,
.altInput,
.Select_title,
.findButton,
.controls {
  height: 51px;
}
.input,
.altInput {
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.Select {
  margin-right: 0;
}

.findButton {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0 40px;
  border: 0;
  outline: none;
  border-radius: 4px;
  height: 51px;
  line-height: 51px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.lower {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.99%;
}

.lower .findButton {
  margin: 0 auto;
}

.slidecontainer {
  flex-basis: 95%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .slidecontainer {
    flex-basis: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (min-width: 1024px) {
  .lower .findButton {
    margin: 0;
  }
  .upper,
  .lower {
    margin-bottom: 20px;
  }
  .Select,
  .slidecontainer {
    flex-basis: 32%;
    margin: 0;
  }

  .input {
    flex-basis: 51.8%;
  }
  .altInput {
    flex-basis: 85.9%;
  }
}
@media (min-width: 1280px) {
  .input {
    flex-basis: 53.2%;
  }
  .altInput {
    flex-basis: 87.1%;
  }
}
@media (min-width: 1440px) {
  .input {
    flex-basis: 54.3%;
  }
  .altInput {
    flex-basis: 88.3%;
  }
}
