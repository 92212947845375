.LatestCompanies {
  overflow: hidden;
}

.LatestCompaniesItem {
  min-width: 100%;
  margin-right: 5%;
}

.LatestCompanies_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 65px;
  align-items: center;
}

.LatestCompanies_title {
  font-size: 28px;
  line-height: 38px;
  font-weight: normal;
}

.LatestCompanies_bottom {
  display: flex;
  transition: transform 600ms ease;
}

.LatestCompanies_controls {
  width: 5rem;
  display: flex;
  justify-content: space-between;
}

.LatestCompanies_controls img {
  cursor: pointer;
}

.radioButtonsWrapper {
  display: flex;
  flex-direction: row;
}
.radioButton {
  margin: 5px;
}

@media screen and (min-width: 768px) {
  .LatestCompanies_top {
    padding: 0;
  }

  .LatestCompanies_bottom {
    margin: 0 -15px;
  }

  .LatestCompaniesItem {
    min-width: 50%;
    padding: 1rem 1.5rem;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .LatestCompanies {
    overflow: hidden;
    margin: 0 auto;
  }

  .LatestCompaniesItem {
    min-width: 33.33%;
  }
}
