.container {
  padding: 0 15px;
  background-color: rgb(247, 247, 247);
  position: relative;
  padding: 120px 0 50px;
  margin: 100px 0 50px;
}

.title {
  margin: 0 15px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  font-weight: normal;
  text-align: center;
  width: calc(100% - 30px);
  font-size: 28px;
  line-height: 38px;
  background-color: #fff;
  padding: 22px 50px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.content {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 140px;
  position: relative;
  width: 140px;
  border-radius: 50%;
  background-color: #2f4161;
  margin-bottom: 25px;
}

.circle img {
  height: 40%;
  width: auto;
  opacity: 1;
  transition: 0.2s ease-in all;
}

.circle p {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  transition: 0.2s ease-in all;
}

.circle:hover img {
  opacity: 0;
  transition: 0.2s ease-out all;
}

.circle:hover p {
  opacity: 1;
  visibility: visible;
  transition: 0.2s ease-out all;
}

.item_title {
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 18px;
}

.item_desc {
  font-size: 16px;
  color: #888;
  text-align: center;
}

@media (min-width: 768px) {
  .title {
    transform: translate(-50%, -50%);
    margin-left: 50%;
    width: unset;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .title:hover {
    background-color: #2f4161;
    box-shadow: none;
    color: #fff;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .content {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .item {
    width: 50%;
    padding: 0 10px;
  }
}

@media (min-width: 1024px) {
  .item {
    width: 25%;
  }
}
