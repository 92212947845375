.about_company {
  width: 100%;
  margin: 0 auto;
}

.map_location {
  height: 400px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.about_company__info {
  padding: 1rem 2rem;
  background-color: #eee;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100px;
}

.working_hours,
.phone {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.about_company__info h3 {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.about_company__info p {
  font-size: 1rem;
}

.textButton {
  padding: 0.6rem 1.5rem;
  transition: 0.2s all ease;
  color: #2f4161;
  text-align: center;
  display: flex;
  top: 60%;
  padding-right: 4.5rem;
  font-weight: 600;
  justify-content: center;
  background: linear-gradient(to top, #f7f7f7 0%, rgba(255, 255, 255, 0.1));
  width: 100%;
}

.textButton:hover {
  transition: 0.2s all ease;
}

.desc_label {
  margin: 50px 0 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
}
.desc_label h3 {
  font-size: 22px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}
.description {
  max-height: 240px;
  overflow: hidden;
  position: relative;
  transition: all 0.4s;
}
.fullDescription {
  max-height: 2000px;
}

.description p {
  font-size: 15px;
  line-height: 27px;
  color: #707070;
}

.gradient {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 0;
  height: 180px;
  background: linear-gradient(rgba(255, 255, 255, 0), #fcfcfc 100%);
  opacity: 1;
  transition: opacity 0.5;
}
.gradient_hidden {
  opacity: 0;
  transition: opacity 0.5;
}
.map_location h3 {
  margin-bottom: 30px;
}

.promo_video {
  margin-top: 120px;
  margin-bottom: 50px;
}

.video_wrapper {
  margin-top: 30px;
}

video {
  width: 100%;
}

@media screen and (min-width: 540px) {
  .about_company__info h3 {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  .about_company__info p {
    font-size: 1.4rem;
  }
}
