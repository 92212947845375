.main_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 15px;
}

@media screen and (min-width: 768px) {
  .main_content {
    flex-direction: row;
  }
}

@media screen and (min-width: 1280px) {
  .main_content {
    padding: 20px 95px;
  }
}

@media screen and (min-width: 1440px) {
  .main_content {
    padding: 20px 140px;
  }
}
