.paginate ul {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin: 2rem 0 6rem 0;
}

.paginate {
  padding: 0 2rem;
}

.paginate li {
  text-decoration: none;
  outline: none;
  list-style: none;
  cursor: pointer;
  margin: 0 0.5rem;
}

.paginate li,
.paginate a {
  color: #888;
  background-color: #f2f2f2;
}

.paginate a {
  height: 100%;
  border-radius: 4px;
  width: 100%;
  padding: 0.2rem 0.2rem;
  font-size: 14px;
}

.pageActive {
  color: green;
  background-color: #fff;
}

.linkActive {
  color: #fff !important;
  background-color: #2f4161 !important;
}

.previous,
.next {
  position: absolute;
  margin: 0 !important;
}

.previous {
  left: 0;
}

.next {
  right: 0;
}

@media screen and (min-width: 480px) {
  .paginate a {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .paginate a {
    padding: 1.5rem 2rem;
  }
}
