.PrivacyAgreement {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.PrivacyAgreement h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.PrivacyAgreement h3 {
  margin-bottom: 30px;
  font-size: 22px;
  margin-top: 80px;
}

.PrivacyAgreement h6 {
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.PrivacyAgreement p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #888888;
}

.PrivacyAgreement li {
  padding-left: 4px;
  margin-left: 40px;
  font-size: 16px;
  margin-bottom: 4px;
  color: #888888;
}

.PrivacyAgreement ul {
  margin-bottom: 40px;
}

.PrivacyAgreement span {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}