.Mobile_navigation {
  position: fixed;
  width: 80%;
  visibility: hidden;
  height: 100vh;
  background-color: #2a2a3a;
  z-index: 3;
  transition: 0.5s visibility ease;
}

.Mobile_navigation__active {
  visibility: visible;
}
.Mobile_title {
  color: #fff;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  padding: 10px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  background-color: #262626;
  line-height: 30px;
  height: 50px;
}

.Mobile_button {
  color: #fff;
  text-align: center;
  width: 100%;
  font-weight: 400;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #404040;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  background-color: transparent;
  pointer-events: all;
  transition: 0.3s ease-in background-color;
}

.Mobile_button:hover {
  background-color: #404040;
  transition: 0.3s ease-out background-color;
}
