.singleSlider_wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.slider {
  display: flex;
  flex-direction: row;
  transition: transform 600ms ease;
}

.arrowLeft,
.arrowRight {
  position: absolute;
  top: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.arrowLeft {
  right: 3rem;
}

.arrowRight {
  right: 0;
}

.slideContainer {
  min-width: 100%;
  position: relative;
}

.slide {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.slider_info {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0;
  transform: translateY(35%);
  transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
}

.slideContainer:hover .slider_info {
  transform: translateY(0%);
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
}

.info_upper {
  background: linear-gradient(to bottom, transparent 20%, #2f2f2f);
  padding-top: 8rem;
  color: #fff;
  padding-left: 3rem;
  padding-bottom: 5.5rem;
}

.info_lower {
  background-color: #2f4161;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.slider_info h3 {
  margin: 0;
  margin-top: 2rem;
}
