@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
.Select_Select__ja7ii {
  width: 100%;
  background-color: #fcfcfc;
  color: #888;
  margin-bottom: 14px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  position: relative;
}

.Select_Select_optionsVisible__1DrhU {
  border-radius: 0;
}

.Select_Select_title__3Kwad {
  width: 100%;
  border: 0;
  padding-left: 1.5rem;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  display: flex;
  outline: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5rem;
  color: #888888;
}

.Select_Select_title__3Kwad img {
  height: 15px;
}

.Select_Select_options__1t9q9 {
  display: none;
}

.Select_Select_options_visible__3pA9- {
  padding: 0 8px;
  display: block;
  max-height: 14vmax;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 2;
  padding-bottom: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Select_Select_options_visible__3pA9-::-webkit-scrollbar {
  width: 6px;
}

.Select_Select_options_visible__3pA9-::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.Select_Select_options_visible__3pA9-::-webkit-scrollbar-thumb {
  background: #2f4161;
  border-radius: 10px;
}

.Select_Select_cancelOption__3PyvT {
  display: block;
  border: 0;
  background-color: transparent;
  padding: 10px 10px;
  line-height: 15px;
  word-wrap: break-word;
  text-align: left;
  width: 100%;
  transition: 200ms ease all;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  color: rgb(204, 204, 204);
}

.Select_Select_singleOption__2aXlV {
  display: block;
  border: 0;
  background-color: transparent;
  padding: 10px 10px;
  line-height: 15px;
  word-wrap: break-word;
  text-align: left;
  width: 100%;
  transition: 200ms ease all;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}

.Select_Select_singleOption__2aXlV:hover,
.Select_Select_singleOption_selected__feztX {
  background-color: #2f4161;
  color: #fff;
  border-radius: 4px;
}

.Select_Select_cancelOption__3PyvT:hover,
.Select_Select_cancelOption_selected__3W8oV {
  background-color: #2f4161;
  color: #fff;
  border-radius: 4px;
}

@media (min-width: 1024px) {
  .Select_Select__ja7ii {
    width: 23%;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.FindSolution_FindSolution__3q7_q {
  padding: 100px 0;
  background-image: url(/static/media/findsolution.04c0ebd4.jpg);
  background-size: cover;
  background-attachment: fixed;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  background-repeat: no-repeat;
  color: #fff;
  overflow: hidden;
  position: relative;
  margin-top: -10px;
}

.FindSolution_FindSoluton_title__3NmKn {
  margin: 20px 0 30px;
  font-size: 40px;
}
.FindSolution_FindSolution_Select__FNYBV {
  font-size: 17px;
}

.FindSolution_FindSolution_advanced__3XjFy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}

.FindSolution_FindSolution_advaned_title__3NHUF {
  font-size: 15px;
}

.FindSolution_FindSolution_advanced_button__3ZQMY {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 7px 12px;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
}

.FindSolution_FindSolution_advanced_button__3ZQMY:hover {
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.25);
}

.FindSolution_FindSolution_solutions__20cp0 {
  margin-top: 40px;
  font-size: 24px;
  font-weight: normal;
}

.FindSolution_FindSolution_form_input__1dkfO {
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 4px;
  border-color: transparent;
}

.FindSolution_FindSolution_form_button__2qA2B {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0;
  border: 0;
  outline: none;
  height: 57px;
  line-height: 57px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: opacity 0.2s ease-in;
  opacity: 0.85;
}

.FindSolution_FindSolution_form_button__2qA2B:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.FindSolution_FindSolution_form_button__2qA2B img {
  height: 18px;
}

@media (min-width: 768px) {
  .FindSolution_FindSolution__3q7_q {
    padding: 100px 0;
  }

  .FindSolution_FindSolution_form_buttonWrapper__1r-wO {
    display: flex;
    justify-content: space-between;
  }

  .FindSolution_FindSolution_advanced__3XjFy {
    flex-direction: row;
    align-items: center;
  }

  .FindSolution_FindSolution_advanced_button__3ZQMY {
    margin-left: 7px;
  }

  .FindSolution_FindSolution_form_button__2qA2B {
    width: 130px;
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  .FindSolution_FindSolution__3q7_q {
    padding: 125px 0;
  }

  .FindSolution_FindSolution_form__2hGZy {
    display: flex;
    align-items: center;
  }

  .FindSolution_FindSolution_form_input__1dkfO {
    margin-bottom: 0;
  }

  .FindSolution_FindSolution_form_buttonWrapper__1r-wO {
    flex: 1 1;
  }
}

@media screen and (min-width: 1280px) {
  .FindSolution_FindSolution__3q7_q {
    padding: 170px 0;
  }
}

.HowCanWeHelp_container__3HuAX {
  padding: 0 15px;
  background-color: rgb(247, 247, 247);
  position: relative;
  padding: 120px 0 50px;
  margin: 100px 0 50px;
}

.HowCanWeHelp_title__2aZ65 {
  margin: 0 15px;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: normal;
  text-align: center;
  width: calc(100% - 30px);
  font-size: 28px;
  line-height: 38px;
  background-color: #fff;
  padding: 22px 50px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.HowCanWeHelp_content__Uka61 {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}

.HowCanWeHelp_item__11Gr9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.HowCanWeHelp_circle__bL5-v {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 140px;
  position: relative;
  width: 140px;
  border-radius: 50%;
  background-color: #2f4161;
  margin-bottom: 25px;
}

.HowCanWeHelp_circle__bL5-v img {
  height: 40%;
  width: auto;
  opacity: 1;
  transition: 0.2s ease-in all;
}

.HowCanWeHelp_circle__bL5-v p {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  transition: 0.2s ease-in all;
}

.HowCanWeHelp_circle__bL5-v:hover img {
  opacity: 0;
  transition: 0.2s ease-out all;
}

.HowCanWeHelp_circle__bL5-v:hover p {
  opacity: 1;
  visibility: visible;
  transition: 0.2s ease-out all;
}

.HowCanWeHelp_item_title__1Vs4l {
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 18px;
}

.HowCanWeHelp_item_desc__zDC4E {
  font-size: 16px;
  color: #888;
  text-align: center;
}

@media (min-width: 768px) {
  .HowCanWeHelp_title__2aZ65 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-left: 50%;
    width: unset;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .HowCanWeHelp_title__2aZ65:hover {
    background-color: #2f4161;
    box-shadow: none;
    color: #fff;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .HowCanWeHelp_content__Uka61 {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .HowCanWeHelp_item__11Gr9 {
    width: 50%;
    padding: 0 10px;
  }
}

@media (min-width: 1024px) {
  .HowCanWeHelp_item__11Gr9 {
    width: 25%;
  }
}

.Banners_container__2ounZ {
  padding: 0;
}

.Banners_title__1PGDn {
  text-align: center;
  font-size: 28px;
  line-height: 58px;
  font-weight: normal;
}

.Banners_item_container__2uFv2 {
  height: 350px;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: #2f4161;
  border-radius: 3px;
  margin-bottom: 25px;
}

.Banners_item_container__2uFv2 img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
}

.Banners_item_container__2uFv2:hover img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: 0.5s ease;
  opacity: 0.3;
}

.Banners_subtitle__TpfEA {
  color: #888;
  font-size: 18px;
  text-align: center;
  display: block;
}

.Banners_content__BoR25 {
  margin-top: 40px;
}

.Banners_item_title__1J0Gk {
  font-size: 28px;
  line-height: 36px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.Banners_item_highlighted__1FvDF {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  display: flex;
  position: absolute;
  top: 17px;
  background-color: #2f4161;
  width: 200px;
  left: -67px;
  font-size: 12px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

@media (min-width: 768px) {
  .Banners_container__2ounZ {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .Banners_content__BoR25 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Banners_item_container__2uFv2 {
    margin-bottom: 0;
  }
  .Banners_item_container__2uFv2:nth-child(1) {
    margin-right: 1%;
    margin-bottom: 1%;
  }
  .Banners_item_container__2uFv2:nth-child(2) {
    margin-left: 1%;
    margin-bottom: 1%;
  }

  .Banners_item_container__2uFv2:nth-child(3) {
    margin-right: 1%;
    margin-top: 1%;
  }
  .Banners_item_container__2uFv2:nth-child(4) {
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 25px;
  }

  .Banners_item_container__2uFv2:nth-child(1),
  .Banners_item_container__2uFv2:nth-child(4) {
    width: 33%;
  }

  .Banners_item_container__2uFv2:nth-child(2),
  .Banners_item_container__2uFv2:nth-child(3) {
    width: 65%;
  }
}

.Latest_LatestCompanies__2_zkK {
  overflow: hidden;
}

.Latest_LatestCompaniesItem__2R0G_ {
  min-width: 100%;
  margin-right: 5%;
}

.Latest_LatestCompanies_top__3WHKx {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 65px;
  align-items: center;
}

.Latest_LatestCompanies_title__1ufHZ {
  font-size: 28px;
  line-height: 38px;
  font-weight: normal;
}

.Latest_LatestCompanies_bottom__p7qwG {
  display: flex;
  transition: -webkit-transform 600ms ease;
  transition: transform 600ms ease;
  transition: transform 600ms ease, -webkit-transform 600ms ease;
}

.Latest_LatestCompanies_controls__6XVV9 {
  width: 5rem;
  display: flex;
  justify-content: space-between;
}

.Latest_LatestCompanies_controls__6XVV9 img {
  cursor: pointer;
}

.Latest_radioButtonsWrapper__114LI {
  display: flex;
  flex-direction: row;
}
.Latest_radioButton__2CmIz {
  margin: 5px;
}

@media screen and (min-width: 768px) {
  .Latest_LatestCompanies_top__3WHKx {
    padding: 0;
  }

  .Latest_LatestCompanies_bottom__p7qwG {
    margin: 0 -15px;
  }

  .Latest_LatestCompaniesItem__2R0G_ {
    min-width: 50%;
    padding: 1rem 1.5rem;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .Latest_LatestCompanies__2_zkK {
    overflow: hidden;
    margin: 0 auto;
  }

  .Latest_LatestCompaniesItem__2R0G_ {
    min-width: 33.33%;
  }
}

.ItemLink_container__2NGLy {
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
}

.ItemLink_first__2dlHe {
  padding: 6px;
  background-color: #2f4161;
}

.ItemLink_shadow__Or1Qd {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.ItemLink_noShadow__1h5zJ {
  box-shadow: none;
}

.ItemLink_top__390EL {
  position: relative;
  padding-bottom: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.ItemLink_bottom__3EpGt {
  padding: 15px 25px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ItemLink_gradient__SXmMA {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 500ms ease opacity;
  background: linear-gradient(to bottom, transparent 60%, #2f4161);
}

@media (hover: hover) {
  .ItemLink_container__2NGLy:hover .ItemLink_gradient__SXmMA {
    opacity: 1;
  }
}

.ItemLink_top_highlighted__1G8Ig {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  display: flex;
  position: absolute;
  top: 17px;
  background-color: #2f4161;
  width: 200px;
  left: -67px;
  font-size: 12px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ItemLink_top_premium__o-jxi {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  display: flex;
  padding: 1px 10px;
  font-size: 12px;
  float: right;
  background-color: rgba(74, 74, 76, 0.7);
  height: 30px;
  align-items: center;
  justify-content: center;
}

.ItemLink_name__1OL6o {
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
}

.ItemLink_description_wrapper__1UZyf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ItemLink_description__2Kbuk {
  color: #909090;
  line-height: 24px;
  font-size: 14px;
  margin-right: 0.5rem;
}

@media screen and (min-width: 768px) {
  .ItemLink_altShadow__2UKnR {
    display: flex;
  }
  .ItemLink_altTop__mGGzg {
    padding-bottom: 29%;
    width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .ItemLink_bottom__3EpGt {
    padding: 8%;
  }
}

.filter-slider__details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.filter-slider__details-center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.rc-slider-handle {
  border: 2px solid #2f4161 !important;
  height: 18px !important;
  width: 18px !important;
  top: 15% !important;
}

.rc-slider-rail {
  background-color: #e8e8e8 !important;
}

.rc-slider-track {
  background-color: #2f4161 !important;
}

.rc-slider-rail,
.rc-slider-track {
  height: 3px !important;
}

.rc-slider {
  width: 100% !important;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .rc-slider {
    width: 100% !important;
  }
}

@media screen and (min-width: 1600px) {
  .rc-slider {
    width: 100% !important;
  }
}

.CompaniesForm_container__3VjhJ {
  padding: 32px 40px 47px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.CompaniesForm_container__3VjhJ form {
  width: 100%;
}
.CompaniesForm_title__2nseh {
  margin: 10px 0 34px 0;
  font-size: 24px;
  line-height: 1.1;
  color: #333;
  font-weight: 500;
}

.CompaniesForm_input__2lG5D,
.CompaniesForm_Select_title__3g53r,
.CompaniesForm_findButton__SqyWI,
.CompaniesForm_controls__2HaA8 {
  height: 51px;
}

.CompaniesForm_sliderContainer__31OLP {
  margin: 2rem auto 2rem auto;
  width: 100%;
}

.CompaniesForm_formWithSlider__3YA6g {
  display: flex;
  flex-direction: column;
}

.CompaniesForm_controls__2HaA8 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}

.CompaniesForm_buttons__1tz0K {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.CompaniesForm_wrap__1iDNj {
  flex-wrap: wrap;
}

.CompaniesForm_findButton__SqyWI {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0 40px;
  border: 0;
  outline: none;
  border-radius: 4px;
  height: 51px;
  line-height: 51px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.CompaniesForm_input__2lG5D {
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

@media (min-width: 1024px) {
  .CompaniesForm_sliderContainer__31OLP {
    padding: 0 0 0 2.5rem;
    width: 49%;
  }
  .CompaniesForm_formWithSlider__3YA6g {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .CompaniesForm_space_between__koxFZ {
    display: flex;
    justify-content: space-between;
  }

  .CompaniesForm_input__2lG5D {
    width: 100%;
  }

  .CompaniesForm_Select__1spox {
    margin: 10px 0 10px;
    width: 49%;
  }

  .CompaniesForm_buttons__1tz0K {
    margin-top: 10px;
  }
}

.MoreOptions_controls__2o64u {
  height: 51px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}
.MoreOptions_moreOptions__ON8NL {
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin: 5px 0 0 0;
  margin-top: 35px;
  background-color: transparent;
}
.MoreOptions_moreOptions__ON8NL span {
  font-size: 15px;
}

.MoreOptions_OptionsContainer__1WlP2 {
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}

.MoreOptions_Option__3yDQ5 {
  font-size: 15px;
  margin-top: 9px;
  margin-right: 20px;
  color: #707070;
  display: flex;
  align-items: center;
}
.MoreOptions_Option__3yDQ5 input {
  height: 19px;
}
.MoreOptions_Option__3yDQ5 span {
  font-size: 15px;
  padding-left: 10px;
}

@media screen and (min-width: 768px) {
  .MoreOptions_OptionsContainer__1WlP2 {
    flex-direction: row;
  }
}

@-webkit-keyframes Loader_loader-right-spin__2ss47 {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
}

@keyframes Loader_loader-right-spin__2ss47 {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  100% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
}

@-webkit-keyframes Loader_loader-left-spin__1lD1T {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
}

@keyframes Loader_loader-left-spin__1lD1T {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
}

@-webkit-keyframes Loader_loader-easespin__2aFp9 {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg);
  }

  100% {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@keyframes Loader_loader-easespin__2aFp9 {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg);
  }

  100% {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@-webkit-keyframes Loader_loader-linspin__1_UJc {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader_loader-linspin__1_UJc {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Loader_loader-wrapper__rW6th {
  width: 100%;
  height: 100vh;
  /* background-color: #2f4161; TODO check this*/
  position: relative;
  overflow: hidden;
  z-index: 100000;
}

.Loader_loader__3YH3W {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 64px;
  z-index: 100000;
  background-color: #fff;
  pointer-events: none;
}

.Loader_loader--big__2BktZ {
  width: 160px;
  margin-left: -80px;
  display: flex;
  align-items: center;
  margin-bottom: 13rem;
  top: calc(50% - 13rem);
  justify-content: center;
}

.Loader_loader-image__2TtLq {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Loader_loader-container__3XF-d {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-animation: Loader_loader-linspin__1_UJc 1568.23529647ms linear infinite;
          animation: Loader_loader-linspin__1_UJc 1568.23529647ms linear infinite;
}

.Loader_loader-rotator__38QdO {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: Loader_loader-easespin__2aFp9 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: Loader_loader-easespin__2aFp9 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Loader_loader-left__3Paok {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 49%;
  overflow: hidden;
}

.Loader_loader-right__2Es_w {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 49%;
  overflow: hidden;
}

.Loader_loader-left__3Paok .Loader_loader-circle__nBn2c {
  left: 0;
  right: -100%;
  border-right-color: transparent;
  -webkit-animation: Loader_loader-left-spin__1lD1T 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: Loader_loader-left-spin__1lD1T 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Loader_loader-right__2Es_w .Loader_loader-circle__nBn2c {
  left: -100%;
  right: 0;
  border-left-color: transparent;
  -webkit-animation: Loader_right-spin__3nIKT 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: Loader_right-spin__3nIKT 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.Loader_loader-circle__nBn2c {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  border-color: #ddd #ddd transparent;
  border-radius: 50%;
  border-width: 6px;
}

.Loader_loader--big__2BktZ .Loader_loader-circle__nBn2c {
  border-color: #000 #000 #000 transparent;
  border-width: 3px;
}

.Loader_loader--big__2BktZ .Loader_loader-right__2Es_w {
  left: 50%;
}

.Loader_loader--big__2BktZ .Loader_loader-left__3Paok {
  right: 50%;
}

.Loader_loader--big__2BktZ .Loader_loader-left__3Paok .Loader_loader-circle__nBn2c {
  -webkit-animation: none;
          animation: none;
}

.MapItemLink_container__21ogw {
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
}

.MapItemLink_first__7tdpS {
  padding: 6px;
  background-color: #2f4161;
}

.MapItemLink_shadow__81x6N {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.MapItemLink_noShadow__2npXJ {
  box-shadow: none;
}

.MapItemLink_top__37ic0 {
  position: relative;
  padding-bottom: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-top: 0%;
}

.MapItemLink_bottom__1lWyx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 60px;
  padding: 5%;
}

.MapItemLink_gradient__29cjH {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 500ms ease opacity;
  background: linear-gradient(to bottom, transparent 60%, #2f4161);
}

@media (hover: hover) {
  .MapItemLink_container__21ogw:hover .MapItemLink_gradient__29cjH {
    opacity: 1;
  }
}

.MapItemLink_top_highlighted__1aOtx {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  display: flex;
  position: absolute;
  top: 17px;
  background-color: #2f4161;
  width: 200px;
  left: -67px;
  font-size: 12px;
  height: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.MapItemLink_top_premium__3sP-6 {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  display: flex;
  padding: 1px 10px;
  font-size: 12px;
  float: right;
  background-color: rgba(74, 74, 76, 0.7);
  height: 30px;
  align-items: center;
  justify-content: center;
}

.MapItemLink_name__2VEaz {
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
}

.MapItemLink_description_wrapper__3NROJ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.MapItemLink_description__2H8Sn {
  color: #909090;
  line-height: 24px;
  font-size: 10px;
  margin-right: 0.5rem;
}

@media screen and (min-width: 768px) {
  .MapItemLink_altShadow__26lDX {
    display: flex;
  }
  .MapItemLink_altTop__IWzqv {
    padding-bottom: 29%;
    width: 40%;
  }
}

.Map_cluster_marker__3iL-H {
  color: #fff;
  background: #2f4161;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 16px;
  align-items: center;
  -webkit-transform: scale(1) translate(-50%, -100%);
          transform: scale(1) translate(-50%, -100%);
  -webkit-animation: Map_pulse__yvhDQ 3s infinite;
          animation: Map_pulse__yvhDQ 3s infinite;
}

@-webkit-keyframes Map_pulse__yvhDQ {
  0% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }

  50% {
    /* transform: scale(1) translate(-50%, -100%); */
    box-shadow: 0 0 0 10px rgba(17, 43, 58, 0.3);
  }

  100% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }
}

@keyframes Map_pulse__yvhDQ {
  0% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }

  50% {
    /* transform: scale(1) translate(-50%, -100%); */
    box-shadow: 0 0 0 10px rgba(17, 43, 58, 0.3);
  }

  100% {
    /* transform: scale(0.95) translate(-50%, -100%); */
    box-shadow: 0 0 0 5px rgba(17, 43, 58, 0.3);
  }
}

.Map_selectedCompanyWrapper__JcNi4 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 25vmin;
  height: auto;
  left: 0;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  top: 0;
  cursor: default;
  justify-content: center;
  align-content: center;
  z-index: 1;
}

.Map_selectedCompanyContainer__1mwW9 {
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.Map_closeSelectedCompany__1SwP1 {
  position: absolute;
  top: 7px;
  right: 7px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  color: #000;
  z-index: 10;
}

.Map_pinTriangle__3RFMm {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #fff;
  align-self: center;
}

.Pagination_paginate__1djPi ul {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin: 2rem 0 6rem 0;
}

.Pagination_paginate__1djPi {
  padding: 0 2rem;
}

.Pagination_paginate__1djPi li {
  text-decoration: none;
  outline: none;
  list-style: none;
  cursor: pointer;
  margin: 0 0.5rem;
}

.Pagination_paginate__1djPi li,
.Pagination_paginate__1djPi a {
  color: #888;
  background-color: #f2f2f2;
}

.Pagination_paginate__1djPi a {
  height: 100%;
  border-radius: 4px;
  width: 100%;
  padding: 0.2rem 0.2rem;
  font-size: 14px;
}

.Pagination_pageActive__1xVAW {
  color: green;
  background-color: #fff;
}

.Pagination_linkActive__2kvFo {
  color: #fff !important;
  background-color: #2f4161 !important;
}

.Pagination_previous__3NDrA,
.Pagination_next__1CwCs {
  position: absolute;
  margin: 0 !important;
}

.Pagination_previous__3NDrA {
  left: 0;
}

.Pagination_next__1CwCs {
  right: 0;
}

@media screen and (min-width: 480px) {
  .Pagination_paginate__1djPi a {
    padding: 0.5rem 1rem;
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .Pagination_paginate__1djPi a {
    padding: 1.5rem 2rem;
  }
}

.CompaniesScreen_companiesScreen__2btYu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.CompaniesScreen_mapContainer__10gSQ {
  position: relative;
  top: 0;
  left: 0;
  z-index: 20;
  height: 50vh;
  width: 100%;
  margin-bottom: 0px;
  background-color: #fff;
}

.CompaniesScreen_companiesListContainer__3pe4W {
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0;
}

.CompaniesScreen_companiesList__1okUp {
  padding: 0 40px;
}

@media screen and (min-width: 768px) {
  .CompaniesScreen_companiesList__1okUp {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1024px) {
  .CompaniesScreen_companiesScreen__2btYu {
    height: calc(100vh - 255px);
    flex-direction: row;
  }

  .CompaniesScreen_mapContainer__10gSQ {
    height: 100%;
    width: 45%;
  }

  .CompaniesScreen_companiesListContainer__3pe4W {
    width: 55%;
  }
}

@media screen and (min-width: 1280px) {
  .CompaniesScreen_companiesScreen__2btYu {
    height: calc(100vh - 145px);
  }

  .CompaniesScreen_companiesList__1okUp {
    padding: 0 40px;
  }
}

.LayoutPicker_rowItem__2hypL {
  flex-basis: 100%;
  margin-bottom: 4%;
}
.LayoutPicker_twoRowItem__19oP- {
  flex-basis: 100%;
  margin-bottom: 4%;
}
.LayoutPicker_threeRowItem__3tS_r {
  flex-basis: 100%;
  margin-bottom: 7%;
}

.LayoutPicker_list_buttons__2rs3U {
  display: none;
  justify-content: flex-end;
  align-items: center;
}

.LayoutPicker_list_button__3boKZ {
  width: 36px;
  height: 36px;
  background-color: #f0f0f0;
  border-radius: 3px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.LayoutPicker_list_button__3boKZ:hover {
  background-color: #2f4161;
  transition: all 0.4s;
}
.LayoutPicker_list_button__3boKZ svg {
  color: #a0a0a0;
}
.LayoutPicker_list_button__3boKZ:hover svg {
  color: #f0f0f0;
}

.LayoutPicker_list_button__3boKZ:nth-child(3) {
  display: none;
}

@media screen and (min-width: 768px) {
  .LayoutPicker_twoRowItem__19oP- {
    flex-basis: 48.75% !important;
  }

  .LayoutPicker_threeRowItem__3tS_r {
    flex-basis: 31.66%;
    margin-bottom: 4%;
  }

  .LayoutPicker_rowItem__2hypL {
    flex-basis: 100%;
    width: 100%;
  }

  .LayoutPicker_list_buttons__2rs3U {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .LayoutPicker_list_button__3boKZ:nth-child(3) {
    display: block;
  }

  .LayoutPicker_rowItem__2hypL {
    flex-basis: 100%;
  }
}

.List_list__OQeVf {
  display: flex;
  padding-top: 20px;
  align-items: center;
  flex-direction: column;
}

.List_header__3Hngw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0 25px 0;
}

.List_items__2BbvT {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: 2.5%;
  gap: 2.5%;
}
.List_list_info__3nWnm {
  color: #888;
  font-size: 15px;
}

.List_header_left__19NKX {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 1rem;
  min-height: 40px;
}

.List_header_left__19NKX span {
  width: 30%;
}

.List_select__3vMb7 {
  border: none;
  background-color: #fff;
  width: 70%;
  margin-left: 1rem;
}

@media screen and (min-width: 768px) {
  .List_list_buttons__1n4Y8 {
    display: flex;
  }

  .List_header_left__19NKX {
    justify-content: flex-start;
  }

  .List_header_left__19NKX span {
    width: unset;
  }

  .List_select__3vMb7 {
    width: 35%;
  }
}

@media screen and (min-width: 1024px) {
  .List_list_button__7JOEn:nth-child(3) {
    display: block;
  }

  .List_select__3vMb7 {
    width: 25%;
  }
}

@media screen and (min-width: 1280px) {
  .List_select__3vMb7 {
    width: 22%;
  }
}

@media screen and (min-width: 1440px) {
  .List_select__3vMb7 {
    width: 20%;
  }
}

.CompanyHeader_companyHeader__2GYb_ {
  background: linear-gradient(to bottom, #f7f7f7 0%, rgba(255, 255, 255, 0.5));
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 4.5rem;
  justify-content: space-between;
}

.CompanyHeader_companyHeader__left__1TIHe {
  flex-direction: column;
  display: flex;
}

.CompanyHeader_basicInfo__EWg0R {
  flex-direction: column;
}

.CompanyHeader_basicInfo__upper__CnqWG {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.CompanyHeader_basicInfo__upper__CnqWG h2 {
  font-size: 28px;
  line-height: 36px;
  margin-right: 10px;
}
.CompanyHeader_fakeButton__qM6Xu {
  align-self: center;
  background-color: #2f4161;
  padding: 4px 11px;
  color: #fff;
  border-radius: 3px;
  line-height: 24px;
}

.CompanyHeader_fakeButton__qM6Xu p {
  font-size: 1.3rem;
}

.CompanyHeader_location__3HTEg {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.CompanyHeader_location__3HTEg p {
  color: #808080;
  font-size: 17px;
  color: #888;
}

.CompanyHeader_companyLogo__2KnV9 {
  max-width: 12rem;
  height: auto;
}

.CompanyHeader_companyHeader__right__2LWXE {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

@media screen and (min-width: 540px) and (orientation: portrait) {
  .CompanyHeader_companyHeader__2GYb_ {
    flex-direction: row;
    align-items: flex-start;
  }

  .CompanyHeader_companyHeader__right__2LWXE {
    margin-top: 0;
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (min-width: 540px) {
  .CompanyHeader_basicInfo__upper__CnqWG {
    flex-direction: column;
  }

  .CompanyHeader_fakeButton__qM6Xu p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media screen and (min-width: 768px) {
  .CompanyHeader_companyHeader__2GYb_ {
    flex-direction: row;
    align-items: center;
    padding-top: 5.5rem;
  }

  .CompanyHeader_companyHeader__right__2LWXE {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  }

  .CompanyHeader_companyHeader__left__1TIHe {
    flex-direction: row;
    /* align-items: center; */
  }

  .CompanyHeader_basicInfo__EWg0R {
    margin-left: 20px;
    width: 100%;
  }

  .CompanyHeader_basicInfo__upper__CnqWG {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .CompanyHeader_fakeButton__qM6Xu {
    margin-left: 1rem;
  }

  .CompanyHeader_basicInfo__upper__CnqWG h2 {
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1280px) {
  .CompanyHeader_companyHeader__2GYb_ {
    padding: 40px 90px;
  }
}

@media screen and (min-width: 1440px) {
  .CompanyHeader_companyHeader__2GYb_ {
    padding: 40px 140px;
  }
}

.BackButton_backArrow__11IGy {
  display: flex;
  height: 60px;
  width: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eee;
  margin: 0 25px 0 0;
  transition: 0.2s background-color ease;
}

@media screen and (min-width: 768px) {
  .BackButton_backArrow__11IGy {
    margin-bottom: 0;
  }

  .BackButton_backArrow__11IGy:hover {
    background-color: #2f4161;
    transition: 0.2s background-color ease;
  }
}

.CompanyBanner_companyBanner__3fSod {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.CompanyBanner_active_image__xyEnZ {
  border: 3px solid #2f4161;
  border-radius: 3px;
}
.CompanyBanner_companyBanner__items__1V6wr {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  grid-gap: 2%;
  gap: 2%;
  overflow-x: scroll;
  padding-bottom: 1.5rem;
}

.CompanyBanner_companyBanner__items__1V6wr::-webkit-scrollbar {
  width: 6px;
}

.CompanyBanner_companyBanner__items__1V6wr::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

.CompanyBanner_companyBanner__items__1V6wr::-webkit-scrollbar-thumb {
  display: none;
  background: #2f4161;
  border-radius: 1rem;
}

.CompanyBanner_image__21DOF {
  width: 32%;
  object-fit: cover;
  height: 83px;
  cursor: pointer;
  border-radius: 3px;
}

.CompanyBanner_Gallery_About__28oq5 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.94);
  z-index: 10;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.CompanyBanner_Gallery_About_Visible__FryAw {
  display: flex;
  pointer-events: all;
}

.CompanyBanner_companyBanner__img__2Y2uW {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  cursor: pointer;
  height: 20rem;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) {
  .CompanyBanner_companyBanner__items__1V6wr {
    grid-gap: 1.33%;
    gap: 1.33%;
  }
  .CompanyBanner_companyBanner__img__2Y2uW {
    height: 40rem;
  }
  .CompanyBanner_image__21DOF {
    height: 11.4rem;
    width: 24%;
  }
}

@media screen and (min-width: 1024px) {
  .CompanyBanner_companyBanner__items__1V6wr {
    grid-gap: 1%;
    gap: 1%;
  }
  .CompanyBanner_companyBanner__img__2Y2uW {
    height: 50rem;
  }
  .CompanyBanner_image__21DOF {
    height: 10rem;
    width: 15.83%;
  }
}

@media screen and (min-width: 1280px) {
  .CompanyBanner_companyBanner__img__2Y2uW {
    height: 60rem;
  }
  .CompanyBanner_image__21DOF {
    height: 11.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .CompanyBanner_image__21DOF {
    height: 12.5rem;
  }
}

.AboutCompany_about_company__2ClX4 {
  width: 100%;
  margin: 0 auto;
}

.AboutCompany_map_location__1lZrb {
  height: 400px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.AboutCompany_about_company__info__ETYJS {
  padding: 1rem 2rem;
  background-color: #eee;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100px;
}

.AboutCompany_working_hours__zxX8S,
.AboutCompany_phone__1Pfw9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.AboutCompany_about_company__info__ETYJS h3 {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.AboutCompany_about_company__info__ETYJS p {
  font-size: 1rem;
}

.AboutCompany_textButton__3tGnq {
  padding: 0.6rem 1.5rem;
  transition: 0.2s all ease;
  color: #2f4161;
  text-align: center;
  display: flex;
  top: 60%;
  padding-right: 4.5rem;
  font-weight: 600;
  justify-content: center;
  background: linear-gradient(to top, #f7f7f7 0%, rgba(255, 255, 255, 0.1));
  width: 100%;
}

.AboutCompany_textButton__3tGnq:hover {
  transition: 0.2s all ease;
}

.AboutCompany_desc_label__JFsXY {
  margin: 50px 0 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
}
.AboutCompany_desc_label__JFsXY h3 {
  font-size: 22px;
}

.AboutCompany_descriptionContainer__2gXW2 {
  display: flex;
  flex-direction: column;
}
.AboutCompany_description__tn54H {
  max-height: 240px;
  overflow: hidden;
  position: relative;
  transition: all 0.4s;
}
.AboutCompany_fullDescription__2UlsW {
  max-height: 2000px;
}

.AboutCompany_description__tn54H p {
  font-size: 15px;
  line-height: 27px;
  color: #707070;
}

.AboutCompany_gradient__2rH1H {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 0;
  height: 180px;
  background: linear-gradient(rgba(255, 255, 255, 0), #fcfcfc 100%);
  opacity: 1;
  transition: opacity 0.5;
}
.AboutCompany_gradient_hidden__2-1Rn {
  opacity: 0;
  transition: opacity 0.5;
}
.AboutCompany_map_location__1lZrb h3 {
  margin-bottom: 30px;
}

.AboutCompany_promo_video__3ptCC {
  margin-top: 120px;
  margin-bottom: 50px;
}

.AboutCompany_video_wrapper__21Y9W {
  margin-top: 30px;
}

video {
  width: 100%;
}

@media screen and (min-width: 540px) {
  .AboutCompany_about_company__info__ETYJS h3 {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  .AboutCompany_about_company__info__ETYJS p {
    font-size: 1.4rem;
  }
}

.Gallery_container__1KOEq {
  display: flex;
  flex-direction: column;
}

.Gallery_wrapper__Yi6LF {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 2%;
  gap: 2%;
  margin-bottom: 2rem;
}

.Gallery_item__Gxmb9 {
  flex-basis: 49%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 2%;
}

.Gallery_item_bckg__3Q1Wh {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 75%;
}

.Gallery_item_paragraph__13Rg9 {
  position: absolute;
  opacity: 0;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  transition: 0.3s ease-in all;
}

.Gallery_item_bckg__3Q1Wh:hover .Gallery_item_paragraph__13Rg9 {
  opacity: 1;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.3s ease-out all;
  background-color: #2f4161;
  border-radius: 3px;
}

.Gallery_item_bckg__3Q1Wh:hover .Gallery_gradient__2LEMY {
  opacity: 0.8;
}

.Gallery_item_paragraph__13Rg9 p {
  padding: 10px 12px;
  color: #fff;
  font-size: 12px;
}

.Gallery_item__Gxmb9 h3 {
  text-align: center;
  padding: 2rem;
  font-weight: 500;
  font-size: 14px;
}

.Gallery_header__2Q0bw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.Gallery_header__2Q0bw h3 {
  cursor: pointer;
}

.Gallery_breadcrumbs__XkerZ {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
}

.Gallery_breadcrumbs__XkerZ h4 {
  cursor: pointer;
}

.Gallery_gallery_360image__1d-OB {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px) {
  .Gallery_breadcrumbs__XkerZ h4 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1024px) {
  .Gallery_item__Gxmb9 {
    flex-basis: 31%;
    margin-bottom: 2.5%;
  }

  .Gallery_item__Gxmb9 h3 {
    text-align: left;
  }

  .Gallery_breadcrumbs__XkerZ p {
    margin: 0 0.5rem;
  }
}

.Tabs_tabs__3FzkG {
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  overflow-x: hidden;
}

.Tabs_container__3wbKK {
  display: flex;
  margin-bottom: 0px;
  align-items: center;
  overflow-x: scroll;
}

.Tabs_container__3wbKK::-webkit-scrollbar {
  display: none;
}

.Tabs_tab__1vwe_ {
  display: inline-block;
  flex-shrink: 0;
  padding: 16px 5px 8px 5px;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  color: #333333;
  font-weight: 500;
  cursor: pointer;
  margin-right: 4px;
}

.Tabs_tab__1vwe_ p {
  font-size: 1.4rem;
}

.Tabs_tab__1vwe_:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2f4161;
  height: 2px;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.Tabs_tab_active__3EjMy {
  color: #2f4161;
}

.Tabs_tab_active__3EjMy:before,
.Tabs_tab__1vwe_:hover,
.Tabs_tab__1vwe_:hover:before,
.Tabs_tab__1vwe_:focus:before,
.Tabs_tab__1vwe_:active:before {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  color: #2f4161;
}

@media screen and (min-width: 768px) {
  .Tabs_tab__1vwe_ p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .Tabs_container__3wbKK {
    width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .Tabs_container__3wbKK {
    width: 70%;
  }

  .Tabs_tab__1vwe_ {
    font-size: 16px;
  }
}

.Associates_wrapper__2fkd_ {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.Catalogue_input__12fL5 {
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.CompanyDetails_companyDetails__36viG {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .CompanyDetails_companyDetails__36viG {
    width: 65%;
  }
}

.SingleItemSlider_singleSlider_wrapper__VC4tG {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.SingleItemSlider_slider__28m7q {
  display: flex;
  flex-direction: row;
  transition: -webkit-transform 600ms ease;
  transition: transform 600ms ease;
  transition: transform 600ms ease, -webkit-transform 600ms ease;
}

.SingleItemSlider_arrowLeft__lVblV,
.SingleItemSlider_arrowRight__hGlw5 {
  position: absolute;
  top: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.SingleItemSlider_arrowLeft__lVblV {
  right: 3rem;
}

.SingleItemSlider_arrowRight__hGlw5 {
  right: 0;
}

.SingleItemSlider_slideContainer__1O6I_ {
  min-width: 100%;
  position: relative;
}

.SingleItemSlider_slide__zfuWX {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.SingleItemSlider_slider_info__uxDE6 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0;
  -webkit-transform: translateY(35%);
          transform: translateY(35%);
  transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
}

.SingleItemSlider_slideContainer__1O6I_:hover .SingleItemSlider_slider_info__uxDE6 {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
}

.SingleItemSlider_info_upper__LAk7C {
  background: linear-gradient(to bottom, transparent 20%, #2f2f2f);
  padding-top: 8rem;
  color: #fff;
  padding-left: 3rem;
  padding-bottom: 5.5rem;
}

.SingleItemSlider_info_lower__7EftE {
  background-color: #2f4161;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.SingleItemSlider_slider_info__uxDE6 h3 {
  margin: 0;
  margin-top: 2rem;
}

.CompanySidebar_company_sidebar__1GtEh {
  width: 100%;
}

.CompanySidebar_singleItemSlider_container__3tzO4 {
  position: relative;
}

.CompanySidebar_singleItemSlider_container__3tzO4 h3 {
  font-size: 24px;
}

.CompanySidebar_company_sidebar__1GtEh button {
  background-color: #2f4161;
  border-radius: 3px;
  width: 100%;
  margin: 0;
  cursor: pointer;
  color: #fff;
  margin-top: 5px;
  padding: 6px 16px;
  line-height: 32px;
  font-size: 15px;
  border: 1px solid transparent;
}

.CompanySidebar_contact_wrapper__TMhUE {
  height: 400px;
  background-color: #fff;
  width: 100%;
  margin-top: 4rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 0 3rem;
}

.CompanySidebar_contact_wrapper_hidden__1ThdG {
  display: none;
}

.CompanySidebar_company_sidebar__1GtEh h3 {
  margin: 40px 0 20px 0px;
}

.CompanySidebar_itemLink_slider__27092 {
  position: relative;
  overflow: hidden;
}

.CompanySidebar_itemLink_container__24haw {
  display: flex;
}

.CompanySidebar_itemLink_container__24haw a {
  flex-shrink: 0;
  width: 100%;
}

.CompanySidebar_arrowBack__2-0RX,
.CompanySidebar_arrowForw__2rLdO {
  position: absolute;
  height: 25px;
  width: 25px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.CompanySidebar_arrowBack__2-0RX {
  left: 0;
}

.CompanySidebar_arrowForw__2rLdO {
  right: 0;
}

.CompanySidebar_contact_header__MD1wM img {
  width: 8rem;
  height: auto;
}

.CompanySidebar_contact_header__MD1wM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CompanySidebar_contact_form__BPte9 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CompanySidebar_contact_form__BPte9 input,
.CompanySidebar_contact_form__BPte9 textarea {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 1.5rem;
  outline: none;
  border-color: #eee;
  text-decoration: none;
  color: grey;
  border-style: solid;
  resize: none;
}

.CompanySidebar_contact_form__BPte9 textarea {
  height: 12rem;
}

.CompanySidebar_contact_submit__bQu2e {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.CompanySidebar_contact_submit__bQu2e button {
  padding: 1rem 1.5rem;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  background-color: #2f4161;
}

@media screen and (min-width: 768px) {
  .CompanySidebar_contact_header__MD1wM {
    justify-content: space-between;
  }
  .CompanySidebar_contact_header__MD1wM img {
    width: 5rem;
    margin-right: 1rem;
    height: auto;
  }
  .CompanySidebar_company_sidebar__1GtEh {
    width: 30%;
    margin-left: 5%;
  }

  .CompanySidebar_singleItemSlider_container__3tzO4 h3 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .CompanySidebar_singleItemSlider_container__3tzO4 h3 {
    font-size: 24px;
  }
  .CompanySidebar_contact_header__MD1wM img {
    width: 7rem;
    height: auto;
  }
}

.CompanyScreen_main_content__3nDnm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 15px;
}

@media screen and (min-width: 768px) {
  .CompanyScreen_main_content__3nDnm {
    flex-direction: row;
  }
}

@media screen and (min-width: 1280px) {
  .CompanyScreen_main_content__3nDnm {
    padding: 20px 95px;
  }
}

@media screen and (min-width: 1440px) {
  .CompanyScreen_main_content__3nDnm {
    padding: 20px 140px;
  }
}

.PrivacyAgreement_PrivacyAgreement__2ol0V {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V h3 {
  margin-bottom: 30px;
  font-size: 22px;
  margin-top: 80px;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V h6 {
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #888888;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V li {
  padding-left: 4px;
  margin-left: 40px;
  font-size: 16px;
  margin-bottom: 4px;
  color: #888888;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V ul {
  margin-bottom: 40px;
}

.PrivacyAgreement_PrivacyAgreement__2ol0V span {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
.TermsOfUse_TermsOfUse__1ePOU {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.TermsOfUse_TermsOfUse__1ePOU h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.TermsOfUse_TermsOfUse__1ePOU h6 {
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.TermsOfUse_TermsOfUse__1ePOU p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #888888;
}

.TermsOfUse_TermsOfUse__1ePOU span {
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

.TermsOfUse_Italic__1_Gx5 {
  font-style: italic;
  color: #000 !important;
  margin-bottom: 40px !important;
}
.ServicePackages_ServicePackages__2IgPr {
  display: flex;
  flex-direction: column;
}

.ServicePackages_Boxes__2y9Q1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ServicePackages_Sellers__3XXEF,
.ServicePackages_Manufacturers__1LwHt {
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 20% 0 20%;
}

.ServicePackages_Sellers__3XXEF .ServicePackages_Info__3I9Qx h2,
.ServicePackages_Manufacturers__1LwHt .ServicePackages_Info__3I9Qx h2 {
  font-size: 30px;
}

.ServicePackages_Sellers__3XXEF .ServicePackages_Info__3I9Qx {
  color: #d4d4d4;
}

.ServicePackages_Sellers__3XXEF {
  background-color: #2f4161;
}

.ServicePackages_Manufacturers__1LwHt {
  background-color: #eee;
}

.ServicePackages_ServicePackages__2IgPr .ServicePackages_Info__3I9Qx {
  width: 100%;
  margin: 0 auto;
}

.ServicePackages_Info__3I9Qx h2 {
  margin-bottom: 20px;
}

.ServicePackages_BoxOne__B8LIk,
.ServicePackages_BoxTwo__3PBQs,
.ServicePackages_BoxThree__1ScYx {
  flex-basis: 100%;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 20px;
  box-shadow: 6px 6px 2px 0px rgba(0, 0, 0, 0.25);
}

.ServicePackages_BoxOne__B8LIk:hover,
.ServicePackages_BoxTwo__3PBQs:hover,
.ServicePackages_BoxThree__1ScYx:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ServicePackages_Boxes__2y9Q1 p {
  color: #808080;
}

.ServicePackages_BoxTitle__1AxHL,
.ServicePackages_BoxDesc__hXIiT {
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 10px;
}

.ServicePackages_BoxTitle__1AxHL {
  padding-bottom: 10px;
}

.ServicePackages_BoxTitle__1AxHL {
  margin-bottom: 20px !important;
  color: #808080;
}

.ServicePackages_BoxDesc__hXIiT {
  padding-bottom: 10px;
}

.ServicePackages_BoxDesc__hXIiT p {
  margin-bottom: 5px;
}

.ServicePackages_BoxPrice__Dce5M {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ServicePackages_BoxPrice__Dce5M h5,
.ServicePackages_BoxPrice__Dce5M p {
  font-size: 10px;
}

.ServicePackages_BoxPrice__Dce5M h5,
.ServicePackages_BoxPrice__Dce5M p {
  margin-bottom: 0;
}

.ServicePackages_BoxPrice__Dce5M div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 1024px) {
  .ServicePackages_ServicePackages__2IgPr {
    display: flex;
    flex-direction: column;
  }

  .ServicePackages_Sellers__3XXEF,
  .ServicePackages_Manufacturers__1LwHt {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 20% 0 20%;
  }

  .ServicePackages_Sellers__3XXEF .ServicePackages_Info__3I9Qx h2,
  .ServicePackages_Manufacturers__1LwHt .ServicePackages_Info__3I9Qx h2 {
    font-size: 30px;
  }

  .ServicePackages_Sellers__3XXEF .ServicePackages_Info__3I9Qx {
    color: #d4d4d4;
  }

  .ServicePackages_Sellers__3XXEF {
    background-color: #2f4161;
  }

  .ServicePackages_Manufacturers__1LwHt {
    background-color: #eee;
  }

  .ServicePackages_ServicePackages__2IgPr .ServicePackages_Info__3I9Qx {
    width: 60%;
    margin: 0 auto;
  }

  .ServicePackages_Info__3I9Qx h2 {
    margin-bottom: 20px;
  }

  .ServicePackages_Boxes__2y9Q1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .ServicePackages_BoxOne__B8LIk,
  .ServicePackages_BoxTwo__3PBQs,
  .ServicePackages_BoxThree__1ScYx {
    flex-basis: 32%;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 6px 6px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .ServicePackages_Boxes__2y9Q1 p {
    color: #808080;
  }

  .ServicePackages_BoxTitle__1AxHL,
  .ServicePackages_BoxDesc__hXIiT {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 10px;
  }

  .ServicePackages_BoxTitle__1AxHL {
    padding-bottom: 10px;
  }

  .ServicePackages_BoxTitle__1AxHL {
    margin-bottom: 20px !important;
    color: #808080;
  }

  .ServicePackages_BoxDesc__hXIiT {
    padding-bottom: 10px;
  }

  .ServicePackages_BoxDesc__hXIiT p {
    margin-bottom: 5px;
  }

  .ServicePackages_BoxPrice__Dce5M {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .ServicePackages_BoxPrice__Dce5M h5,
  .ServicePackages_BoxPrice__Dce5M p {
    font-size: 10px;
  }

  .ServicePackages_BoxPrice__Dce5M h5,
  .ServicePackages_BoxPrice__Dce5M p {
    margin-bottom: 0;
  }

  .ServicePackages_BoxPrice__Dce5M div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.AddCompanyScreen_container__369Hi {
  width: 80%;
  margin: auto;
  padding: 40px 0;
}

.AddCompanyScreen_title__1o8fp {
  padding: 10px 0 34px;
}

.AddCompanyScreen_find__1-eto {
  margin-top: 60px;
}

.AddCompanyScreen_input__33PqQ {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  height: 57px;
  line-height: 57px;
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .AddCompanyScreen_container__369Hi {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .AddCompanyScreen_container__369Hi {
    width: 40%;
  }
}

.HowCanWeHelp_HowCanWeHelp_wrapper__1d0-e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0;
}

.HowCanWeHelp_SecondSection__1MRGw {
  width: 100%;
}

.ProductsForm_container__2HFzC {
  width: 100%;
  padding: 32px 0px 47px 0px;
}

.ProductsForm_title__HsmvH {
  margin: 10px 0 34px 0;
  font-size: 24px;
  line-height: 1.1;
  color: #333;
  font-weight: 500;
}

.ProductsForm_upper__2Xx1v {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 1.99%;
  gap: 1.99%;
}

.ProductsForm_input__3xBWS,
.ProductsForm_altInput__WHxHH,
.ProductsForm_Select_title__1OhMz,
.ProductsForm_findButton__3hkUV,
.ProductsForm_controls__3Rv7X {
  height: 51px;
}
.ProductsForm_input__3xBWS,
.ProductsForm_altInput__WHxHH {
  width: 100%;
  padding: 0 20px;
  font-size: 17px;
  outline: none;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.ProductsForm_Select__1UBfr {
  margin-right: 0;
}

.ProductsForm_findButton__3hkUV {
  cursor: pointer;
  background-color: #2f4161;
  padding: 0 40px;
  border: 0;
  outline: none;
  border-radius: 4px;
  height: 51px;
  line-height: 51px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.ProductsForm_lower__1-5AM {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 1.99%;
  gap: 1.99%;
}

.ProductsForm_lower__1-5AM .ProductsForm_findButton__3hkUV {
  margin: 0 auto;
}

.ProductsForm_slidecontainer__1eVd7 {
  flex-basis: 95%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .ProductsForm_slidecontainer__1eVd7 {
    flex-basis: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (min-width: 1024px) {
  .ProductsForm_lower__1-5AM .ProductsForm_findButton__3hkUV {
    margin: 0;
  }
  .ProductsForm_upper__2Xx1v,
  .ProductsForm_lower__1-5AM {
    margin-bottom: 20px;
  }
  .ProductsForm_Select__1UBfr,
  .ProductsForm_slidecontainer__1eVd7 {
    flex-basis: 32%;
    margin: 0;
  }

  .ProductsForm_input__3xBWS {
    flex-basis: 51.8%;
  }
  .ProductsForm_altInput__WHxHH {
    flex-basis: 85.9%;
  }
}
@media (min-width: 1280px) {
  .ProductsForm_input__3xBWS {
    flex-basis: 53.2%;
  }
  .ProductsForm_altInput__WHxHH {
    flex-basis: 87.1%;
  }
}
@media (min-width: 1440px) {
  .ProductsForm_input__3xBWS {
    flex-basis: 54.3%;
  }
  .ProductsForm_altInput__WHxHH {
    flex-basis: 88.3%;
  }
}

.ProductScreen_singleProductScreen__1FQ6V {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  margin-top: 2rem;
}

.ProductScreen_product_header__t9QyM {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-items: flex-start;
  max-width: 100%;
  margin-bottom: 2rem;
}

.ProductScreen_product_header__left__l2deA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

.ProductScreen_product_price__KbdxD,
.ProductScreen_product_price__discount__3Fdn4 {
  font-size: 1.6rem !important;
  color: #2f4161 !important;
  margin-top: 2rem;
}

.ProductScreen_product_price_disabled__1T-IJ {
  text-decoration: line-through;
  color: #d4d4d4 !important;
}

.ProductScreen_product_price__discount__3Fdn4 {
  margin-top: 0.5rem;
}

.ProductScreen_product_header_image__3VBrJ {
  width: 100%;
}

.ProductScreen_product_header_content__34uQb {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ProductScreen_product_title__39e6D {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.ProductScreen_product_title__39e6D div {
  display: flex;
  flex-direction: column;
}

.ProductScreen_product_filters__3IHUc {
  width: 20%;
}

.ProductScreen_product_filters__3IHUc img {
  height: 10vmin;
}

.ProductScreen_product_title__39e6D p {
  font-size: 18px;
  color: #808080;
  margin: 0.3rem 0;
}

.ProductScreen_product_title__39e6D a {
  margin-bottom: 1rem;
}

.ProductScreen_product_decription__Cnk9i {
  margin: 0 0 1rem;
  font-size: 14px;
  color: #888888;
  line-height: 22px;
}

.ProductScreen_product_info__ajjYp {
  background: #eee;
  color: #909090;
  padding: 1rem 1.6rem 1rem 1.5rem;
  margin: 1rem 0;
  height: 10vmax;
  overflow-y: hidden;
}

.ProductScreen_product_info__ajjYp div {
  overflow-y: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ProductScreen_product_info__ajjYp div::-webkit-scrollbar {
  width: 10px;
}

.ProductScreen_product_info__ajjYp div:hover {
  overflow-y: scroll;
}

.ProductScreen_product_info__ajjYp div::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.ProductScreen_product_info__ajjYp div::-webkit-scrollbar-thumb {
  background: #2f4161;
  border-radius: 10px;
}

.ProductScreen_product_info__ajjYp span {
  font-size: 14px;
  font-weight: 700;
}

.ProductScreen_product_info__ajjYp p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.ProductScreen_product_info__ajjYp img {
  margin-right: 0.8rem;
  width: 30px;
}

@media screen and (min-width: 640px) {
  .ProductScreen_product_filters__3IHUc img {
    height: 8vmin;
  }
}

@media screen and (min-width: 768px) {
  .ProductScreen_product_header_image__3VBrJ {
    width: 100%;
    height: 100%;
    max-height: 20vmax;
    object-fit: cover;
    padding: 1rem;
    padding-right: 0;
  }

  .ProductScreen_product_header_content__34uQb {
    width: 60%;
  }

  .ProductScreen_product_header__t9QyM {
    display: flex;
    flex-direction: row;
  }

  .ProductScreen_product_filters__3IHUc img {
    height: 6vmin;
  }

  .ProductScreen_product_header__left__l2deA {
    width: 40%;
    align-items: flex-end;
  }

  .ProductScreen_product_title__39e6D {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .ProductScreen_product_filters__3IHUc img {
    height: 4vmax;
  }

  .ProductScreen_product_header__t9QyM {
    margin-top: 0rem;
  }

  .ProductScreen_product_info__ajjYp {
    padding: 3rem 1rem 3rem 3rem;
  }
}

@media screen and (min-width: 1280px) {
  .ProductScreen_product_header__t9QyM {
    margin-top: 2%;
  }

  .ProductScreen_product_filters__3IHUc img {
    height: 3vmax;
  }
}

.ProductCatalogueScreen_singleProductScreen__2ew_5 {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  margin-top: 2rem;
}

.ProductCatalogueScreen_product_header__3u5Im {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-items: flex-start;
  max-width: 100%;
  margin-bottom: 2rem;
}

.ProductCatalogueScreen_product_header__left__2doZ4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 2rem;
}

.ProductCatalogueScreen_product_price__3UWr2,
.ProductCatalogueScreen_product_price__discount__2whP4 {
  font-size: 1.6rem !important;
  color: #2f4161 !important;
  margin-top: 2rem;
}

.ProductCatalogueScreen_product_price_disabled__1vEom {
  text-decoration: line-through;
  color: #d4d4d4 !important;
}

.ProductCatalogueScreen_product_price__discount__2whP4 {
  margin-top: 0.5rem;
}

.ProductCatalogueScreen_product_header_image__2l78S {
  width: 100%;
}

.ProductCatalogueScreen_product_header_content__2mrB4 {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ProductCatalogueScreen_product_title__3wtJ5 {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.ProductCatalogueScreen_product_title__3wtJ5 div {
  display: flex;
  flex-direction: column;
}

.ProductCatalogueScreen_product_filters__2Lf1N {
  width: 20%;
}

.ProductCatalogueScreen_product_filters__2Lf1N img {
  height: 10vmin;
}

.ProductCatalogueScreen_product_title__3wtJ5 p {
  font-size: 18px;
  color: #808080;
  margin: 0.3rem 0;
}

.ProductCatalogueScreen_product_title__3wtJ5 a {
  margin-bottom: 1rem;
}

.ProductCatalogueScreen_product_decription__1gqGt {
  margin: 0 0 1rem;
  font-size: 14px;
  color: #888888;
  line-height: 22px;
}

.ProductCatalogueScreen_product_info__2XjuZ {
  background: #eee;
  color: #909090;
  padding: 1rem 1.6rem 1rem 1.5rem;
  margin: 1rem 0;
  height: 10vmax;
  overflow-y: hidden;
}

.ProductCatalogueScreen_product_info__2XjuZ div {
  overflow-y: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ProductCatalogueScreen_product_info__2XjuZ div::-webkit-scrollbar {
  width: 10px;
}

.ProductCatalogueScreen_product_info__2XjuZ div:hover {
  overflow-y: scroll;
}

.ProductCatalogueScreen_product_info__2XjuZ div::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.ProductCatalogueScreen_product_info__2XjuZ div::-webkit-scrollbar-thumb {
  background: #2f4161;
  border-radius: 10px;
}

.ProductCatalogueScreen_product_info__2XjuZ span {
  font-size: 14px;
  font-weight: 700;
}

.ProductCatalogueScreen_product_info__2XjuZ p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.ProductCatalogueScreen_product_info__2XjuZ img {
  margin-right: 0.8rem;
  width: 30px;
}

@media screen and (min-width: 640px) {
  .ProductCatalogueScreen_product_filters__2Lf1N img {
    height: 8vmin;
  }
}

@media screen and (min-width: 768px) {
  .ProductCatalogueScreen_product_header_image__2l78S {
    width: 100%;
    height: 100%;
    max-height: 20vmax;
    object-fit: cover;
    padding: 1rem;
    padding-right: 0;
  }

  .ProductCatalogueScreen_product_header_content__2mrB4 {
    width: 60%;
  }

  .ProductCatalogueScreen_product_header__3u5Im {
    display: flex;
    flex-direction: row;
  }

  .ProductCatalogueScreen_product_filters__2Lf1N img {
    height: 6vmin;
  }

  .ProductCatalogueScreen_product_header__left__2doZ4 {
    width: 40%;
    align-items: flex-end;
  }

  .ProductCatalogueScreen_product_title__3wtJ5 {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .ProductCatalogueScreen_product_filters__2Lf1N img {
    height: 4vmax;
  }

  .ProductCatalogueScreen_product_header__3u5Im {
    margin-top: 0rem;
  }

  .ProductCatalogueScreen_product_info__2XjuZ {
    padding: 3rem 1rem 3rem 3rem;
  }
}

@media screen and (min-width: 1280px) {
  .ProductCatalogueScreen_product_header__3u5Im {
    margin-top: 2%;
  }

  .ProductCatalogueScreen_product_filters__2Lf1N img {
    height: 3vmax;
  }
}

.NotFoundScreen_notFoundContainer__18_wL {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
}

.NotFoundScreen_notFoundText__3jM4Z {
  font-size: 30px;
  color: #2f4161;
}

.Footer_Facebook__1WU-Y,
.Footer_Pinterest__hg23e,
.Footer_Instagram__2W3DA,
.Footer_Twitter__e7-eh,
.Footer_Linkedin__DIcpD {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: visible;
}

.Footer_Facebook__1WU-Y img,
.Footer_Pinterest__hg23e img,
.Footer_Instagram__2W3DA img,
.Footer_Twitter__e7-eh img,
.Footer_Linkedin__DIcpD img {
  padding: 1.5rem 1rem 1.5rem 0;
}

.Footer_Footer__1n4gD {
  width: 100%;
  background-color: #fcfcfc;
  box-shadow: inset 0 20px 50px -20px rgba(0, 0, 0, 0.06);
  bottom: 0;
  z-index: 1;
}
.Footer_Footer__1n4gD h4 {
  color: #333;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.Footer_Footer_container__35k9S {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 70px 0;
}

.Footer_Footer_info__29Eb4 {
  display: flex;
  flex-direction: column;
  color: #808080;
}
.Footer_Footer_info__29Eb4 img {
  height: 4rem;
  margin-bottom: 1.7rem;
}
.Footer_Footer_info__29Eb4 p {
  margin: 0 0 17px;
  line-height: 24px;
  font-size: 15px;
}
.Footer_Footer_links__30YJ4 {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  color: #808080;
  justify-content: flex-start;
}

.Footer_Footer_links_container__1NBW3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Footer_Footer_links__30YJ4 h4 {
  color: #333;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.Footer_Footer_link_nav__1XwYh {
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 15px;
  line-height: 27px;
}

.Footer_Footer_links_right__kL4dh {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
}

.Footer_Footer_links_left__1AXYK {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
}

.Footer_Footer_links__30YJ4 a:hover {
  color: #808080;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_Footer_contact__37TMh {
  width: 100%;
}

.Footer_Footer_contact__info__3py06 {
  display: flex;
  flex-direction: column;
  color: #808080;
  margin-bottom: 10px;
}

.Footer_Footer_contact__info__3py06 a {
  color: #707070;
  font-size: 15px;
  line-height: 27px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_Footer_contact__info__3py06 a:hover {
  color: #808080;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Footer_Footer_contact__social_links__gGjRs {
  display: flex;
  width: 50%;
  color: #808080;
  height: 60px;
  overflow: hidden;
  align-items: center;
}

.Footer_Footer_copyright__2Wdmo {
  display: flex;
  color: #707070;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding-top: 40px;
  font-size: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .Footer_Footer__1n4gD {
    position: fixed;
  }

  .Footer_Footer_container__35k9S {
    padding-top: 70px;
  }

  .Footer_Footer_main_container__3fVJt {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
  }
  .Footer_Footer_info__29Eb4 {
    flex-basis: 50%;
    padding-right: 15px;
  }
  .Footer_Footer_links__30YJ4 {
    flex-basis: 50%;
    padding-left: 15px;
  }

  .Footer_Footer_links_container__1NBW3 {
    align-items: flex-start;
    width: 100%;
  }

  .Footer_Facebook__1WU-Y,
  .Footer_Instagram__2W3DA,
  .Footer_Twitter__e7-eh,
  .Footer_Linkedin__DIcpD {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    background-color: transparent;
    transition: 0.2s ease-in all;
  }

  .Footer_Facebook__1WU-Y:hover,
  .Footer_Instagram__2W3DA:hover,
  .Footer_Twitter__e7-eh:hover,
  .Footer_Linkedin__DIcpD:hover {
    transition: 0.2s ease-out all;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@media screen and (min-width: 1024px) {
  .Footer_Footer_main_container__3fVJt {
    display: flex;
    flex-wrap: wrap;
  }

  .Footer_Footer_info__29Eb4 {
    flex-basis: 42%;
  }
  .Footer_Footer_links__30YJ4 {
    flex-basis: 33%;
  }
  .Footer_Footer_contact__37TMh {
    flex-basis: 25%;
  }
}

.TopBar_Navigation_upper_container__A5yxi {
  width: 100%;
  background-color: #2f4161;
}

.TopBar_Navigation_upper__LtN7L {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10rem;
}

.TopBar_Navigation_upper__left__q_cgP,
.TopBar_Navigation_upper__right__2gauQ {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.TopBar_Navigation_upper__left__q_cgP {
  width: 100%;
  justify-content: space-between;
  padding: 8px 0;
  align-items: flex-start;
}

.TopBar_Navigation_upper__right__2gauQ {
  justify-content: flex-end;
}

.TopBar_Navigation_upper__left__q_cgP a {
  padding: 4px 0;
}

.TopBar_Navigation_upper__right__2gauQ p {
  padding: 5px 10px 10px 0;
}

.TopBar_TopBar_social__2pptn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: visible;
}

.TopBar_TopBar_social__2pptn img {
  padding: 1.5rem 1rem 1.5rem 0;
  height: 100%;
}

.TopBar_TopBar_info__3IPIl {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.TopBar_TopBar_info_icon__3m8XW {
  size: 'lg';
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 13px;
}

.TopBar_TopBar_info__3IPIl a {
  color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.TopBar_TopBar_info__3IPIl a:hover {
  color: #d4d4d4;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media screen and (min-width: 768px) {
  .TopBar_Navigation_upper__left__q_cgP p {
    padding-right: 20px;
  }

  .TopBar_TopBar_info__3IPIl {
    margin-right: 20px;
  }
  .TopBar_Navigation_upper__left__q_cgP {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .TopBar_Navigation_upper__LtN7L {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    height: 4.5rem;
  }

  .TopBar_Navigation_upper__left__q_cgP,
  .TopBar_Navigation_upper__right__2gauQ {
    width: auto;
  }

  .TopBar_TopBar_social__2pptn {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    background-color: transparent;
    transition: 0.2s ease-in all;
  }

  .TopBar_TopBar_social__2pptn img {
    padding: 1.5rem 1.5rem;
  }

  .TopBar_TopBar_social__2pptn:hover {
    background-color: #fff;
    transition: 0.2s ease-out all;
    -webkit-transform: translateY(-99%);
            transform: translateY(-99%);
  }

  .TopBar_TopBar_social__2pptn:hover img {
    background-color: #fff;
  }
}

.Navigation_Navigation__1z7EC {
  display: flex;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  top: -5rem;
  justify-content: center;
  background-color: #fff;
  transition: 0.3s all ease-out;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.Navigation_Navigation_transformed__wEYp6 {
  transition: 0.3s all ease-in;
}

.Navigation_button__2xGpQ:hover,
.Navigation_Main_button__3Y0p4:hover {
  background-color: #2f4161;
  color: #fff;
  transition: 0.25s all ease-out;
}
.Navigation_Main_button__3Y0p4 {
  color: #000;
  transition: 0.25s all ease-out;
  border-radius: 5px;
  outline: none;
}

.Navigation_Navigation_lower_container__29VcH {
  width: 100%;
}

.Navigation_Navigation_lower_container__29VcH {
  background-color: #fff;
}

.Navigation_Navigation__1z7EC p {
  color: #fff;
}

.Navigation_Navigation_lower__l58Ca {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.Navigation_Navigation_lower__upper__176Gs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 93px;
  border-bottom: 1px solid #ccc;
}

.Navigation_Navigation_lower__lower__1Ev_l {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
}

.Navigation_Navigation_lower__lower__1Ev_l p {
  color: #000;
}

.Navigation_Navigation_large__2dPkh,
.Navigation_Navigation_large_desktop__24Asb {
  display: none;
}

.Navigation_Menu_mobile__2Qj6R {
  width: 50px;
  background-color: #eee;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.Navigation_Navigation_lower__l58Ca {
  width: 100%;
}

.Navigation_button__2xGpQ {
  padding: 6px 16px;
  line-height: 32px;
  font-size: 15px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.Navigation_main__3XVYR {
  color: #2f4161;
  border: 1px solid #2f4161;
}

.Navigation_Navigation_large__2dPkh a,
.Navigation_Navigation_large_desktop__24Asb a {
  margin: 0 5px;
}

.Navigation_Navigation_large__2dPkh a:nth-child(1),
.Navigation_Navigation_large_desktop__24Asb a:nth-child(1) {
  margin: 0 5px;
}

.Navigation_Logo__179wu {
  height: 50px;
  width: 30%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 25%;
}

.Navigation_BurgerSpan__BY8ni {
  height: 3px;
  width: 25px;
  display: block;
  background-color: #000;
  transition: all 0.25s ease-in-out;
  position: absolute;
  border-radius: 10px;
}

.Navigation_BurgerSpan_top__3ry9Y {
  top: 40%;
}

.Navigation_BurgerSpan_center__gRWdT {
  top: 48%;
}

.Navigation_BurgerSpan_bottom__3Xr16 {
  top: 56%;
}

@media screen and (min-width: 768px) {
  .Navigation_Navigation_transformed__wEYp6 {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: 0.3s all ease-in;
  }
  .Navigation_Navigation__1z7EC {
    top: -4.5rem;
  }
  .Navigation_Navigation_lower__l58Ca {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .Navigation_Menu_mobile__2Qj6R {
    display: none;
  }
  .Navigation_Navigation__1z7EC {
    position: -webkit-sticky;
    position: sticky;
  }
  .Navigation_Navigation_lower__l58Ca {
    justify-content: space-between;
    position: relative;
  }

  .Navigation_Navigation_lower__lower__1Ev_l {
    position: absolute;
    right: 0;
    height: 100%;
    width: 230px;
    margin-right: 15px;
  }

  .Navigation_Logo__179wu {
    position: inherit;
    margin: 0;
    width: auto;
  }

  .Navigation_Navigation_large__2dPkh {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding: 30px 15px;
  }
}

@media screen and (min-width: 1280px) {
  .Navigation_Navigation_large__2dPkh {
    display: none;
  }

  .Navigation_Navigation_large_desktop__24Asb {
    display: flex;
    margin-left: 40px;
  }

  .Navigation_Navigation_lower__upper__176Gs {
    border-bottom: none;
  }

  .Navigation_Navigation_lower__lower__1Ev_l {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .Navigation_Navigation_lower__lower__1Ev_l {
    padding: 20px 0;
  }
}

.MobileNavigation_Mobile_navigation__1OG3a {
  position: fixed;
  width: 80%;
  visibility: hidden;
  height: 100vh;
  background-color: #2a2a3a;
  z-index: 3;
  transition: 0.5s visibility ease;
}

.MobileNavigation_Mobile_navigation__active__1evI1 {
  visibility: visible;
}
.MobileNavigation_Mobile_title__q4ymr {
  color: #fff;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  padding: 10px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  background-color: #262626;
  line-height: 30px;
  height: 50px;
}

.MobileNavigation_Mobile_button__2dYqL {
  color: #fff;
  text-align: center;
  width: 100%;
  font-weight: 400;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #404040;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  background-color: transparent;
  pointer-events: all;
  transition: 0.3s ease-in background-color;
}

.MobileNavigation_Mobile_button__2dYqL:hover {
  background-color: #404040;
  transition: 0.3s ease-out background-color;
}

.App {
  display: flex;
  flex-direction: column;
  color: #000;
  z-index: 3;
  position: relative;
}

.Content {
  z-index: 3;
  -webkit-transform: none;
          transform: none;
  transition: 300ms ease transform;
  position: relative;
  background: linear-gradient(to bottom, #f7f7f7 0%, rgba(255, 255, 255, 0.5));
  background-color: #fff;
  min-height: 100vh;
}

.Content--translated {
  -webkit-transform: translateX(80%);
          transform: translateX(80%);
}

.BackToTop {
  position: fixed;
  width: 5rem;
  height: 5rem;
  background-color: #2f4161;
  bottom: 4%;
  right: 2.5%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s ease-in opacity;
}

.BackToTop--visible {
  opacity: 1;
  transition: 0.2s ease-out opacity;
}

.Footer-margin {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .Footer-margin {
    margin-bottom: 639px;
  }
}

@media screen and (min-width: 1024px) {
  .Footer-margin {
    margin-bottom: 441px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  font-family: 'Varela Round', 'HelveticaNeue', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #2f4161;
}

p,
a,
li,
span,
button {
  font-size: 1.3rem;
}

button {
  border: none;
  outline: none;
}

h3 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2rem;
}

.noScrollable {
  overflow: hidden;
}

#root {
  height: 100%;
}

.global-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.gradient {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5)
  );
  background-color: #f7f7f7;
}

@media (min-width: 768px) {
  .global-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .global-container {
    width: 970px;
  }

  button {
    font-size: 1.5rem;
  }
}

@media (min-width: 1240px) {
  .global-container {
    width: 1090px;
  }
}

@media (min-width: 1367px) {
  .global-container {
    width: 1210px;
  }
}

